@import "_elements.module"
/**
/** UI Elements
/**

// HR
hr
	border-bottom: 1px solid $black
	border-top: none
	border-radius: 12px
	width: 100%

.button
	+button

.button-3d
	+button-3d

.button-secondary
	+button-secondary

.button-tertiary
	+button-tertiary

.button-link
	+button-link

// Form Elements
.field-wrapper
	+field-wrapper

.field-label
	+field-label

.form-input
	+form-input

.form-checkbox
	+form-checkbox

.form-radio
	+form-radio

.form-checkbox-label, .form-radio-label
	margin-bottom: 0


// Image Wrappers
=image-wrapper
	position: relative
	width: 100%
	img
		position: absolute
		inset: 0%
		width: 100%
		height: 100%
		object-fit: cover

.image-wrapper-1-1
	padding-top: 100%
	+image-wrapper

.image-wrapper-4-3
	padding-top: 75%
	+image-wrapper

.image-wrapper-3-2
	padding-top: 66.66%
	+image-wrapper

.image-wrapper-16-9
	padding-top: 56.25%
	+image-wrapper
