$icon-activity-filled: "\e900"
$icon-activity-light: "\e901"
$icon-add-circled-filled: "\e902"
$icon-add-circled-light: "\e903"
$icon-add-filled: "\e904"
$icon-add-light: "\e905"
$icon-alarm-filled: "\e906"
$icon-alarm-light: "\e907"
$icon-alert-circled-filled: "\e908"
$icon-alert-circled-light: "\e909"
$icon-anchor-filled: "\e90a"
$icon-anchor-light: "\e90b"
$icon-android-filled: "\e90c"
$icon-android-light: "\e90d"
$icon-apple-filled: "\e90e"
$icon-apple-light: "\e90f"
$icon-archive-filled: "\e910"
$icon-archive-light: "\e911"
$icon-arrow-backward-filled: "\e912"
$icon-arrow-backward-light: "\e913"
$icon-arrow-down-filled: "\e914"
$icon-arrow-down-light: "\e915"
$icon-arrow-forward-filled: "\e916"
$icon-arrow-forward-light: "\e917"
$icon-arrow-up-down-filled: "\e918"
$icon-arrow-up-down-light: "\e919"
$icon-arrow-up-filled: "\e91a"
$icon-arrow-up-light: "\e91b"
$icon-at-sign-filled: "\e91c"
$icon-at-sign-light: "\e91d"
$icon-attachment-filled: "\e91e"
$icon-attachment-light: "\e91f"
$icon-badge-filled: "\e920"
$icon-badge-light: "\e921"
$icon-bank-filled: "\e922"
$icon-bank-light: "\e923"
$icon-bar-chart-filled: "\e924"
$icon-bar-chart-light: "\e925"
$icon-basket-filled: "\e926"
$icon-basket-light: "\e927"
$icon-basketball-filled: "\e928"
$icon-basketball-light: "\e929"
$icon-battery-charging-filled: "\e92a"
$icon-battery-charging-light: "\e92b"
$icon-battery-filled: "\e92c"
$icon-battery-full-filled: "\e92d"
$icon-battery-full-light: "\e92e"
$icon-battery-half-filled: "\e92f"
$icon-battery-half-light: "\e930"
$icon-battery-light: "\e931"
$icon-battery-low-filled: "\e932"
$icon-battery-low-light: "\e933"
$icon-bluetooth-filled: "\e934"
$icon-bluetooth-light: "\e935"
$icon-bluetooth-off-filled: "\e936"
$icon-bluetooth-off-light: "\e937"
$icon-bold-filled: "\e938"
$icon-bold-light: "\e939"
$icon-book-filled: "\e93a"
$icon-book-light: "\e93b"
$icon-bookmark-filled: "\e93c"
$icon-bookmark-light: "\e93d"
$icon-box-filled: "\e93e"
$icon-box-light: "\e93f"
$icon-briefcase-filled: "\e940"
$icon-briefcase-light: "\e941"
$icon-brush-filled: "\e942"
$icon-brush-light: "\e943"
$icon-bug-filled: "\e944"
$icon-bug-light: "\e945"
$icon-building-filled: "\e946"
$icon-building-light: "\e947"
$icon-bulb-filled: "\e948"
$icon-bulb-light: "\e949"
$icon-burger-filled: "\e94a"
$icon-burger-light: "\e94b"
$icon-bus-filled: "\e94c"
$icon-bus-light: "\e94d"
$icon-cake-filled: "\e94e"
$icon-cake-light: "\e94f"
$icon-calculator-filled: "\e950"
$icon-calculator-light: "\e951"
$icon-calendar-filled: "\e952"
$icon-calendar-light: "\e953"
$icon-camera-filled: "\e954"
$icon-camera-light: "\e955"
$icon-cart-filled: "\e956"
$icon-cart-light: "\e957"
$icon-cast-filled: "\e958"
$icon-cast-light: "\e959"
$icon-category-filled: "\e95a"
$icon-category-light: "\e95b"
$icon-center-align-filled: "\e95c"
$icon-center-align-light: "\e95d"
$icon-check-all-filled: "\e95e"
$icon-check-all-light: "\e95f"
$icon-check-circled-filled-1: "\e960"
$icon-check-circled-filled: "\e961"
$icon-check-circled-light: "\e962"
$icon-check-filled: "\e963"
$icon-check-light: "\e964"
$icon-checkbox-filled: "\e965"
$icon-checkbox-light: "\e966"
$icon-checkbox-unchecked-filled: "\e967"
$icon-checkbox-unchecked-light: "\e968"
$icon-chevron-backward-filled: "\e969"
$icon-chevron-backward-light: "\e96a"
$icon-chevron-double-backward-filled: "\e96b"
$icon-chevron-double-backward-light: "\e96c"
$icon-chevron-double-down-filled: "\e96d"
$icon-chevron-double-down-light: "\e96e"
$icon-chevron-double-forward-filled: "\e96f"
$icon-chevron-double-forward-light: "\e970"
$icon-chevron-double-up-filled: "\e971"
$icon-chevron-double-up-light: "\e972"
$icon-chevron-down-filled: "\e973"
$icon-chevron-down-light: "\e974"
$icon-chevron-forward-filled: "\e975"
$icon-chevron-forward-light: "\e976"
$icon-chevron-up-filled: "\e977"
$icon-chevron-up-light: "\e978"
$icon-chip-filled: "\e979"
$icon-chip-light: "\e97a"
$icon-chrome-filled: "\e97b"
$icon-chrome-light: "\e97c"
$icon-clipboard-filled: "\e97d"
$icon-clipboard-light: "\e97e"
$icon-clock-filled: "\e97f"
$icon-clock-light: "\e980"
$icon-close-circled-filled: "\e981"
$icon-close-circled-light: "\e982"
$icon-close-filled: "\e983"
$icon-close-light-1: "\e984"
$icon-close-light: "\e985"
$icon-cloud-check-filled: "\e986"
$icon-cloud-check-light: "\e987"
$icon-cloud-download-filled: "\e988"
$icon-cloud-download-light: "\e989"
$icon-cloud-filled: "\e98a"
$icon-cloud-light: "\e98b"
$icon-cloud-off-filled: "\e98c"
$icon-cloud-off-light: "\e98d"
$icon-cloud-upload-filled: "\e98e"
$icon-cloud-upload-light: "\e98f"
$icon-code-filled: "\e990"
$icon-code-light: "\e991"
$icon-coffee-filled: "\e992"
$icon-coffee-light: "\e993"
$icon-collapse-filled: "\e994"
$icon-collapse-light: "\e995"
$icon-color-palette-filled: "\e996"
$icon-color-palette-light: "\e997"
$icon-command-filled: "\e998"
$icon-command-light: "\e999"
$icon-compass-filled: "\e99a"
$icon-compass-light: "\e99b"
$icon-copy-filled: "\e99c"
$icon-copy-light: "\e99d"
$icon-crop-filled: "\e99e"
$icon-crop-light: "\e99f"
$icon-crosshairs-filled: "\e9a0"
$icon-crosshairs-light: "\e9a1"
$icon-cursor-filled: "\e9a2"
$icon-cursor-light: "\e9a3"
$icon-cycling-filled: "\e9a4"
$icon-cycling-light: "\e9a5"
$icon-dashboard-filled: "\e9a6"
$icon-dashboard-light: "\e9a7"
$icon-database-filled: "\e9a8"
$icon-database-light: "\e9a9"
$icon-design-filled: "\e9aa"
$icon-design-light: "\e9ab"
$icon-diamond-filled: "\e9ac"
$icon-diamond-light: "\e9ad"
$icon-document-filled: "\e9ae"
$icon-document-light: "\e9af"
$icon-dollar-filled: "\e9b0"
$icon-dollar-light: "\e9b1"
$icon-donut-chart-filled: "\e9b2"
$icon-donut-chart-light: "\e9b3"
$icon-download-filled: "\e9b4"
$icon-download-light: "\e9b5"
$icon-drop-filled: "\e9b6"
$icon-drop-light: "\e9b7"
$icon-dumbell-filled: "\e9b8"
$icon-dumbell-light: "\e9b9"
$icon-edit-filled: "\e9ba"
$icon-edit-light: "\e9bb"
$icon-euro-filled: "\e9bc"
$icon-euro-light: "\e9bd"
$icon-event-add-filled: "\e9be"
$icon-event-add-light: "\e9bf"
$icon-event-scheduled-filled: "\e9c0"
$icon-event-scheduled-light: "\e9c1"
$icon-expand-filled: "\e9c2"
$icon-expand-light: "\e9c3"
$icon-eye-dropper-filled: "\e9c4"
$icon-eye-dropper-light: "\e9c5"
$icon-flight-light-filled: "\e9c6"
$icon-flight-light-light: "\e9c7"
$icon-facebook-light: "\e9ca"
$icon-fast-backward-filled: "\e9cb"
$icon-fast-backward-light: "\e9cc"
$icon-fast-forward-filled: "\e9cd"
$icon-fast-forward-light: "\e9ce"
$icon-figma-filled: "\e9cf"
$icon-figma-light: "\e9d0"
$icon-file-add-filled: "\e9d1"
$icon-file-add-light: "\e9d2"
$icon-file-filled: "\e9d3"
$icon-file-light: "\e9d4"
$icon-files-filled: "\e9d5"
$icon-files-light: "\e9d6"
$icon-film-filled: "\e9d7"
$icon-film-light: "\e9d8"
$icon-filter-filled: "\e9d9"
$icon-filter-light: "\e9da"
$icon-fire-filled: "\e9db"
$icon-fire-light: "\e9dc"
$icon-flag-filled: "\e9dd"
$icon-flag-light: "\e9de"
$icon-flash-light-filled: "\e9df"
$icon-flash-light-light: "\e9e0"
$icon-folder-add-filled: "\e9e1"
$icon-folder-add-light: "\e9e2"
$icon-folder-filled: "\e9e3"
$icon-folder-light: "\e9e4"
$icon-frown-filled: "\e9e5"
$icon-frown-light: "\e9e6"
$icon-fuel-filled: "\e9e7"
$icon-fuel-light: "\e9e8"
$icon-gamepad-filled: "\e9e9"
$icon-gamepad-light: "\e9ea"
$icon-gift-filled: "\e9eb"
$icon-gift-light: "\e9ec"
$icon-grid-filled: "\e9ed"
$icon-grid-light: "\e9ee"
$icon-group-filled: "\e9ef"
$icon-group-light: "\e9f0"
$icon-growth-filled: "\e9f1"
$icon-growth-light: "\e9f2"
$icon-halfstar-filled: "\e9f3"
$icon-halfstar-light: "\e9f4"
$icon-hand-cursor-filled: "\e9f5"
$icon-hand-cursor-light: "\e9f6"
$icon-hash-filled: "\e9f7"
$icon-hash-light: "\e9f8"
$icon-headphone-filled: "\e9f9"
$icon-headphone-light: "\e9fa"
$icon-heart-filled: "\e9fb"
$icon-heart-light: "\e9fc"
$icon-help-filled: "\e9fd"
$icon-help-light: "\e9fe"
$icon-hide-filled: "\e9ff"
$icon-hide-light: "\ea00"
$icon-history-filled: "\ea01"
$icon-history-light: "\ea02"
$icon-home-filled: "\ea03"
$icon-home-light: "\ea04"
$icon-hourglass-filled: "\ea05"
$icon-hourglass-light: "\ea06"
$icon-hybrid-view-filled: "\ea07"
$icon-hybrid-view-light: "\ea08"
$icon-image-filled: "\ea09"
$icon-image-light: "\ea0a"
$icon-inbox-filled: "\ea0b"
$icon-inbox-light: "\ea0c"
$icon-info-filled: "\ea0d"
$icon-info-light: "\ea0e"
$icon-instagram-filled: "\ea0f"
$icon-instagram-light: "\ea10"
$icon-internet-filled: "\ea11"
$icon-internet-light: "\ea12"
$icon-italics-filled: "\ea13"
$icon-italics-light: "\ea14"
$icon-justify-filled: "\ea15"
$icon-justify-light: "\ea16"
$icon-key-filled: "\ea17"
$icon-key-light: "\ea18"
$icon-keyboard-filled: "\ea19"
$icon-keyboard-light: "\ea1a"
$icon-keypad-filled: "\ea1b"
$icon-keypad-light: "\ea1c"
$icon-laptop-filled: "\ea1d"
$icon-laptop-light: "\ea1e"
$icon-laugh-filled: "\ea1f"
$icon-laugh-light: "\ea20"
$icon-layers-filled: "\ea21"
$icon-layers-light: "\ea22"
$icon-layout-filled: "\ea23"
$icon-layout-light: "\ea24"
$icon-leaderboard-filled: "\ea25"
$icon-leaderboard-light: "\ea26"
$icon-left-align-filled: "\ea27"
$icon-left-align-light: "\ea28"
$icon-line-chart-filled: "\ea29"
$icon-line-chart-light: "\ea2a"
$icon-link-filled: "\ea2b"
$icon-link-light: "\ea2c"
$icon-linkedin-filled: "\ea2d"
$icon-linkedin-light: "\ea2e"
$icon-list-filled: "\ea2f"
$icon-list-light: "\ea30"
$icon-live-filled: "\ea31"
$icon-live-light: "\ea32"
$icon-loader-filled: "\ea33"
$icon-loader-light: "\ea34"
$icon-location-filled: "\ea35"
$icon-location-light: "\ea36"
$icon-lock-filled: "\ea37"
$icon-lock-light: "\ea38"
$icon-login-filled: "\ea39"
$icon-login-light: "\ea3a"
$icon-logout-filled: "\ea3b"
$icon-logout-light: "\ea3c"
$icon-mail-filled: "\ea3d"
$icon-mail-light: "\ea3e"
$icon-map-filled: "\ea3f"
$icon-map-light: "\ea40"
$icon-medal-filled: "\ea41"
$icon-medal-light: "\ea42"
$icon-meh-filled: "\ea43"
$icon-meh-light: "\ea44"
$icon-menu-filled: "\ea45"
$icon-menu-light: "\ea46"
$icon-message-filled: "\ea47"
$icon-message-light: "\ea48"
$icon-messages-filled: "\ea49"
$icon-messages-light: "\ea4a"
$icon-mic-filled: "\ea4b"
$icon-mic-light: "\ea4c"
$icon-mic-off-filled: "\ea4d"
$icon-mic-off-light: "\ea4e"
$icon-mission-filled: "\ea4f"
$icon-mission-light: "\ea50"
$icon-mobile-filled: "\ea51"
$icon-mobile-light: "\ea52"
$icon-moon-filled: "\ea53"
$icon-moon-light: "\ea54"
$icon-more-horizontal-filled: "\ea55"
$icon-more-horizontal-light: "\ea56"
$icon-more-vertical-filled: "\ea57"
$icon-more-vertical-light: "\ea58"
$icon-mouse-filled: "\ea59"
$icon-mouse-light: "\ea5a"
$icon-multiselect-filled: "\ea5b"
$icon-multiselect-light: "\ea5c"
$icon-music-filled: "\ea5d"
$icon-music-light: "\ea5e"
$icon-new-filled: "\ea5f"
$icon-new-light: "\ea60"
$icon-next-filled: "\ea61"
$icon-next-light: "\ea62"
$icon-note-filled: "\ea63"
$icon-note-light: "\ea64"
$icon-notes-filled: "\ea65"
$icon-notes-light: "\ea66"
$icon-notification-filled: "\ea67"
$icon-notification-light: "\ea68"
$icon-notification-off-filled: "\ea69"
$icon-notification-off-light: "\ea6a"
$icon-open-filled: "\ea6b"
$icon-open-light: "\ea6c"
$icon-paint-bucket-filled: "\ea6d"
$icon-paint-bucket-light: "\ea6e"
$icon-paint-roller-filled: "\ea6f"
$icon-paint-roller-light: "\ea70"
$icon-paragraph-filled: "\ea71"
$icon-paragraph-light: "\ea72"
$icon-pause-filled: "\ea73"
$icon-pause-light: "\ea74"
$icon-payment-filled: "\ea75"
$icon-payment-light: "\ea76"
$icon-pen-filled: "\ea77"
$icon-pen-light: "\ea78"
$icon-percent-filled: "\ea79"
$icon-percent-light: "\ea7a"
$icon-person-filled: "\ea7b"
$icon-person-light: "\ea7c"
$icon-phone-call-incoming-filled: "\ea7d"
$icon-phone-call-incoming-light: "\ea7e"
$icon-phone-call-outgoing-filled: "\ea7f"
$icon-phone-call-outgoing-light: "\ea80"
$icon-phone-call-rejected-filled: "\ea81"
$icon-phone-call-rejected-light: "\ea82"
$icon-phone-filled: "\ea83"
$icon-phone-light: "\ea84"
$icon-photos-filled: "\ea85"
$icon-photos-light: "\ea86"
$icon-pie-chart-filled: "\ea87"
$icon-pie-chart-light: "\ea88"
$icon-pin-filled: "\ea89"
$icon-pin-light: "\ea8a"
$icon-pinterest-filled: "\ea8b"
$icon-pinterest-light: "\ea8c"
$icon-pizza-filled: "\ea8d"
$icon-pizza-light: "\ea8e"
$icon-play-circled-filled: "\ea8f"
$icon-play-circled-light: "\ea90"
$icon-play-filled: "\ea91"
$icon-play-light: "\ea92"
$icon-playlist-filled: "\ea93"
$icon-playlist-light: "\ea94"
$icon-power-filled: "\ea95"
$icon-power-light: "\ea96"
$icon-presentation-filled: "\ea97"
$icon-presentation-light: "\ea98"
$icon-previous-filled: "\ea99"
$icon-previous-light: "\ea9a"
$icon-printer-filled: "\ea9b"
$icon-printer-light: "\ea9c"
$icon-privacy-filled: "\ea9d"
$icon-privacy-light: "\ea9e"
$icon-profile-circled-filled: "\ea9f"
$icon-profile-circled-light: "\eaa0"
$icon-promotion-filled: "\eaa1"
$icon-promotion-light: "\eaa2"
$icon-puzzle-filled: "\eaa3"
$icon-puzzle-light: "\eaa4"
$icon-rain-filled: "\eaa5"
$icon-rain-light: "\eaa6"
$icon-receipt-filled: "\eaa7"
$icon-receipt-light: "\eaa8"
$icon-redo-filled: "\eaa9"
$icon-redo-light: "\eaaa"
$icon-reload-filled: "\eaab"
$icon-reload-light: "\eaac"
$icon-remove-filled: "\eaad"
$icon-remove-light: "\eaae"
$icon-repeat-filled: "\eaaf"
$icon-repeat-light: "\eab0"
$icon-right-align-filled: "\eab1"
$icon-right-align-light: "\eab2"
$icon-rocket-filled: "\eab3"
$icon-rocket-light: "\eab4"
$icon-ruler-filled: "\eab5"
$icon-ruler-light: "\eab6"
$icon-rupee-filled: "\eab7"
$icon-rupee-light: "\eab8"
$icon-sad-filled: "\eab9"
$icon-sad-light: "\eaba"
$icon-safe-filled: "\eabb"
$icon-safe-light: "\eabc"
$icon-save-filled: "\eabd"
$icon-save-light: "\eabe"
$icon-scan-filled: "\eabf"
$icon-scan-light: "\eac0"
$icon-schedule-filled: "\eac1"
$icon-schedule-light: "\eac2"
$icon-scissors-filled: "\eac3"
$icon-scissors-light: "\eac4"
$icon-search-filled: "\eac5"
$icon-search-light: "\eac6"
$icon-send-filled: "\eac7"
$icon-send-light: "\eac8"
$icon-server-filled: "\eac9"
$icon-server-light: "\eaca"
$icon-settings-filled: "\eacb"
$icon-settings-light: "\eacc"
$icon-share-filled: "\eacd"
$icon-share-light: "\eace"
$icon-shield-check-filled: "\eacf"
$icon-shield-check-light: "\ead0"
$icon-shield-filled: "\ead1"
$icon-shield-light: "\ead2"
$icon-ship-filled: "\ead3"
$icon-ship-light: "\ead4"
$icon-shopping-bag-filled: "\ead5"
$icon-shopping-bag-light: "\ead6"
$icon-show-filled: "\ead7"
$icon-show-light: "\ead8"
$icon-shuffle-filled: "\ead9"
$icon-shuffle-light: "\eada"
$icon-size-filled: "\eadb"
$icon-size-light: "\eadc"
$icon-slack-filled: "\eadd"
$icon-slack-light: "\eade"
$icon-smart-watch-filled: "\eadf"
$icon-smart-watch-light: "\eae0"
$icon-smile-filled: "\eae1"
$icon-smile-light: "\eae2"
$icon-snapchat-filled: "\eae3"
$icon-snapchat-light: "\eae4"
$icon-snowflake-filled: "\eae5"
$icon-snowflake-light: "\eae6"
$icon-sort-ascending-filled: "\eae7"
$icon-sort-ascending-light: "\eae8"
$icon-sort-decending-filled: "\eae9"
$icon-sort-decending-light: "\eaea"
$icon-sort-filled: "\eaeb"
$icon-sort-light: "\eaec"
$icon-spotify-filled: "\eaed"
$icon-spotify-light: "\eaee"
$icon-spray-filled: "\eaef"
$icon-spray-light: "\eaf0"
$icon-star-filled: "\eaf1"
$icon-star-light: "\eaf2"
$icon-stop-filled: "\eaf3"
$icon-stop-light: "\eaf4"
$icon-store-filled: "\eaf5"
$icon-store-light: "\eaf6"
$icon-sun-filled: "\eaf7"
$icon-sun-light: "\eaf8"
$icon-sync-filled: "\eaf9"
$icon-sync-light: "\eafa"
$icon-table-tennis-filled: "\eafb"
$icon-table-tennis-light: "\eafc"
$icon-tablet-filled: "\eafd"
$icon-tablet-light: "\eafe"
$icon-tag-filled: "\eaff"
$icon-tag-light: "\eb00"
$icon-target-filled: "\eb01"
$icon-target-light: "\eb02"
$icon-thermometer-filled: "\eb03"
$icon-thermometer-light: "\eb04"
$icon-thumbs-down-filled: "\eb05"
$icon-thumbs-down-light: "\eb06"
$icon-thumbs-up-filled: "\eb07"
$icon-thumbs-up-light: "\eb08"
$icon-ticket-filled: "\eb09"
$icon-ticket-light: "\eb0a"
$icon-tile-view-filled: "\eb0b"
$icon-tile-view-light: "\eb0c"
$icon-timer-filled: "\eb0d"
$icon-timer-light: "\eb0e"
$icon-toggle-off-filled: "\eb0f"
$icon-toggle-off-light: "\eb10"
$icon-toggle-on-filled: "\eb11"
$icon-toggle-on-light: "\eb12"
$icon-tool-filled: "\eb13"
$icon-tool-light: "\eb14"
$icon-train-filled: "\eb15"
$icon-train-light: "\eb16"
$icon-trash-filled: "\eb17"
$icon-trash-light: "\eb18"
$icon-trending-down-filled: "\eb19"
$icon-trending-down-light: "\eb1a"
$icon-trending-up-filled: "\eb1b"
$icon-trending-up-light: "\eb1c"
$icon-trophy-filled: "\eb1d"
$icon-trophy-light: "\eb1e"
$icon-truck-filled: "\eb1f"
$icon-truck-light: "\eb20"
$icon-tune-filled: "\eb21"
$icon-tune-light: "\eb22"
$icon-tv-filled: "\eb23"
$icon-tv-light: "\eb24"
$icon-twitch-filled: "\eb25"
$icon-twitch-light: "\eb26"
$icon-twitter-filled: "\eb27"
$icon-twitter-light: "\eb28"
$icon-umbrella-filled: "\eb29"
$icon-umbrella-light: "\eb2a"
$icon-underline-filled: "\eb2b"
$icon-underline-light: "\eb2c"
$icon-undo-filled: "\eb2d"
$icon-undo-light: "\eb2e"
$icon-unlock-filled: "\eb2f"
$icon-unlock-light: "\eb30"
$icon-update-filled: "\eb31"
$icon-update-light: "\eb32"
$icon-upload-filled: "\eb33"
$icon-upload-light: "\eb34"
$icon-utensils-filled: "\eb35"
$icon-utensils-light: "\eb36"
$icon-vector-filled: "\eb37"
$icon-vector-light: "\eb38"
$icon-video-off-filled: "\eb39"
$icon-video-off-light: "\eb3a"
$icon-video-recorder-filled: "\eb3b"
$icon-video-recorder-light: "\eb3c"
$icon-volume-filled: "\eb3d"
$icon-volume-high-filled: "\eb3e"
$icon-volume-high-light: "\eb3f"
$icon-volume-light: "\eb40"
$icon-volume-off-filled: "\eb41"
$icon-volume-off-light: "\eb42"
$icon-wallet-filled: "\eb43"
$icon-wallet-light: "\eb44"
$icon-warning-filled: "\eb45"
$icon-warning-light: "\eb46"
$icon-watch-filled: "\eb47"
$icon-watch-light: "\eb48"
$icon-web-development-filled: "\eb49"
$icon-web-development-light: "\eb4a"
$icon-wifi-filled: "\eb4b"
$icon-wifi-light: "\eb4c"
$icon-wifi-off-filled: "\eb4d"
$icon-wifi-off-light: "\eb4e"
$icon-wind-filled: "\eb4f"
$icon-wind-light: "\eb50"
$icon-window-filled: "\eb51"
$icon-window-light: "\eb52"
$icon-wine-filled: "\eb53"
$icon-wine-light: "\eb54"
$icon-youtube-filled: "\eb55"
$icon-youtube-light: "\eb56"
$icon-zap-filled: "\eb57"
$icon-zap-light: "\eb58"
$icon-zoom-in-filled: "\eb59"
$icon-zoom-in-light: "\eb5a"
$icon-zoom-out-filled: "\eb5b"
$icon-zoom-out-light: "\eb5c"
$icon-apple-logo: "\eb5d"
$icon-facebook-simple-logo: "\eb5e"
$icon-instagram-logo: "\eb63"
$icon-twitter-logo: "\eb64"
$icon-down: "\eb65"
$icon-left: "\eb66"
$icon-right: "\eb67"
$icon-up: "\eb68"
$icon-google-logo: ""
$icon-facebook-filled: ""

:export
	icon-activity-filled: $icon-activity-filled
	icon-activity-light: $icon-activity-light
	icon-add-circled-filled: $icon-add-circled-filled
	icon-add-circled-light: $icon-add-circled-light
	icon-add-filled: $icon-add-filled
	icon-add-light: $icon-add-light
	icon-alarm-filled: $icon-alarm-filled
	icon-alarm-light: $icon-alarm-light
	icon-alert-circled-filled: $icon-alert-circled-filled
	icon-alert-circled-light: $icon-alert-circled-light
	icon-anchor-filled: $icon-anchor-filled
	icon-anchor-light: $icon-anchor-light
	icon-android-filled: $icon-android-filled
	icon-android-light: $icon-android-light
	icon-apple-filled: $icon-apple-filled
	icon-apple-light: $icon-apple-light
	icon-archive-filled: $icon-archive-filled
	icon-archive-light: $icon-archive-light
	icon-arrow-backward-filled: $icon-arrow-backward-filled
	icon-arrow-backward-light: $icon-arrow-backward-light
	icon-arrow-down-filled: $icon-arrow-down-filled
	icon-arrow-down-light: $icon-arrow-down-light
	icon-arrow-forward-filled: $icon-arrow-forward-filled
	icon-arrow-forward-light: $icon-arrow-forward-light
	icon-arrow-up-down-filled: $icon-arrow-up-down-filled
	icon-arrow-up-down-light: $icon-arrow-up-down-light
	icon-arrow-up-filled: $icon-arrow-up-filled
	icon-arrow-up-light: $icon-arrow-up-light
	icon-at-sign-filled: $icon-at-sign-filled
	icon-at-sign-light: $icon-at-sign-light
	icon-attachment-filled: $icon-attachment-filled
	icon-attachment-light: $icon-attachment-light
	icon-badge-filled: $icon-badge-filled
	icon-badge-light: $icon-badge-light
	icon-bank-filled: $icon-bank-filled
	icon-bank-light: $icon-bank-light
	icon-bar-chart-filled: $icon-bar-chart-filled
	icon-bar-chart-light: $icon-bar-chart-light
	icon-basket-filled: $icon-basket-filled
	icon-basket-light: $icon-basket-light
	icon-basketball-filled: $icon-basketball-filled
	icon-basketball-light: $icon-basketball-light
	icon-battery-charging-filled: $icon-battery-charging-filled
	icon-battery-charging-light: $icon-battery-charging-light
	icon-battery-filled: $icon-battery-filled
	icon-battery-full-filled: $icon-battery-full-filled
	icon-battery-full-light: $icon-battery-full-light
	icon-battery-half-filled: $icon-battery-half-filled
	icon-battery-half-light: $icon-battery-half-light
	icon-battery-light: $icon-battery-light
	icon-battery-low-filled: $icon-battery-low-filled
	icon-battery-low-light: $icon-battery-low-light
	icon-bluetooth-filled: $icon-bluetooth-filled
	icon-bluetooth-light: $icon-bluetooth-light
	icon-bluetooth-off-filled: $icon-bluetooth-off-filled
	icon-bluetooth-off-light: $icon-bluetooth-off-light
	icon-bold-filled: $icon-bold-filled
	icon-bold-light: $icon-bold-light
	icon-book-filled: $icon-book-filled
	icon-book-light: $icon-book-light
	icon-bookmark-filled: $icon-bookmark-filled
	icon-bookmark-light: $icon-bookmark-light
	icon-box-filled: $icon-box-filled
	icon-box-light: $icon-box-light
	icon-briefcase-filled: $icon-briefcase-filled
	icon-briefcase-light: $icon-briefcase-light
	icon-brush-filled: $icon-brush-filled
	icon-brush-light: $icon-brush-light
	icon-bug-filled: $icon-bug-filled
	icon-bug-light: $icon-bug-light
	icon-building-filled: $icon-building-filled
	icon-building-light: $icon-building-light
	icon-bulb-filled: $icon-bulb-filled
	icon-bulb-light: $icon-bulb-light
	icon-burger-filled: $icon-burger-filled
	icon-burger-light: $icon-burger-light
	icon-bus-filled: $icon-bus-filled
	icon-bus-light: $icon-bus-light
	icon-cake-filled: $icon-cake-filled
	icon-cake-light: $icon-cake-light
	icon-calculator-filled: $icon-calculator-filled
	icon-calculator-light: $icon-calculator-light
	icon-calendar-filled: $icon-calendar-filled
	icon-calendar-light: $icon-calendar-light
	icon-camera-filled: $icon-camera-filled
	icon-camera-light: $icon-camera-light
	icon-cart-filled: $icon-cart-filled
	icon-cart-light: $icon-cart-light
	icon-cast-filled: $icon-cast-filled
	icon-cast-light: $icon-cast-light
	icon-category-filled: $icon-category-filled
	icon-category-light: $icon-category-light
	icon-center-align-filled: $icon-center-align-filled
	icon-center-align-light: $icon-center-align-light
	icon-check-all-filled: $icon-check-all-filled
	icon-check-all-light: $icon-check-all-light
	icon-check-circled-filled-1: $icon-check-circled-filled-1
	icon-check-circled-filled: $icon-check-circled-filled
	icon-check-circled-light: $icon-check-circled-light
	icon-check-filled: $icon-check-filled
	icon-check-light: $icon-check-light
	icon-checkbox-filled: $icon-checkbox-filled
	icon-checkbox-light: $icon-checkbox-light
	icon-checkbox-unchecked-filled: $icon-checkbox-unchecked-filled
	icon-checkbox-unchecked-light: $icon-checkbox-unchecked-light
	icon-chevron-backward-filled: $icon-chevron-backward-filled
	icon-chevron-backward-light: $icon-chevron-backward-light
	icon-chevron-double-backward-filled: $icon-chevron-double-backward-filled
	icon-chevron-double-backward-light: $icon-chevron-double-backward-light
	icon-chevron-double-down-filled: $icon-chevron-double-down-filled
	icon-chevron-double-down-light: $icon-chevron-double-down-light
	icon-chevron-double-forward-filled: $icon-chevron-double-forward-filled
	icon-chevron-double-forward-light: $icon-chevron-double-forward-light
	icon-chevron-double-up-filled: $icon-chevron-double-up-filled
	icon-chevron-double-up-light: $icon-chevron-double-up-light
	icon-chevron-down-filled: $icon-chevron-down-filled
	icon-chevron-down-light: $icon-chevron-down-light
	icon-chevron-forward-filled: $icon-chevron-forward-filled
	icon-chevron-forward-light: $icon-chevron-forward-light
	icon-chevron-up-filled: $icon-chevron-up-filled
	icon-chevron-up-light: $icon-chevron-up-light
	icon-chip-filled: $icon-chip-filled
	icon-chip-light: $icon-chip-light
	icon-chrome-filled: $icon-chrome-filled
	icon-chrome-light: $icon-chrome-light
	icon-clipboard-filled: $icon-clipboard-filled
	icon-clipboard-light: $icon-clipboard-light
	icon-clock-filled: $icon-clock-filled
	icon-clock-light: $icon-clock-light
	icon-close-circled-filled: $icon-close-circled-filled
	icon-close-circled-light: $icon-close-circled-light
	icon-close-filled: $icon-close-filled
	icon-close-light-1: $icon-close-light-1
	icon-close-light: $icon-close-light
	icon-cloud-check-filled: $icon-cloud-check-filled
	icon-cloud-check-light: $icon-cloud-check-light
	icon-cloud-download-filled: $icon-cloud-download-filled
	icon-cloud-download-light: $icon-cloud-download-light
	icon-cloud-filled: $icon-cloud-filled
	icon-cloud-light: $icon-cloud-light
	icon-cloud-off-filled: $icon-cloud-off-filled
	icon-cloud-off-light: $icon-cloud-off-light
	icon-cloud-upload-filled: $icon-cloud-upload-filled
	icon-cloud-upload-light: $icon-cloud-upload-light
	icon-code-filled: $icon-code-filled
	icon-code-light: $icon-code-light
	icon-coffee-filled: $icon-coffee-filled
	icon-coffee-light: $icon-coffee-light
	icon-collapse-filled: $icon-collapse-filled
	icon-collapse-light: $icon-collapse-light
	icon-color-palette-filled: $icon-color-palette-filled
	icon-color-palette-light: $icon-color-palette-light
	icon-command-filled: $icon-command-filled
	icon-command-light: $icon-command-light
	icon-compass-filled: $icon-compass-filled
	icon-compass-light: $icon-compass-light
	icon-copy-filled: $icon-copy-filled
	icon-copy-light: $icon-copy-light
	icon-crop-filled: $icon-crop-filled
	icon-crop-light: $icon-crop-light
	icon-crosshairs-filled: $icon-crosshairs-filled
	icon-crosshairs-light: $icon-crosshairs-light
	icon-cursor-filled: $icon-cursor-filled
	icon-cursor-light: $icon-cursor-light
	icon-cycling-filled: $icon-cycling-filled
	icon-cycling-light: $icon-cycling-light
	icon-dashboard-filled: $icon-dashboard-filled
	icon-dashboard-light: $icon-dashboard-light
	icon-database-filled: $icon-database-filled
	icon-database-light: $icon-database-light
	icon-design-filled: $icon-design-filled
	icon-design-light: $icon-design-light
	icon-diamond-filled: $icon-diamond-filled
	icon-diamond-light: $icon-diamond-light
	icon-document-filled: $icon-document-filled
	icon-document-light: $icon-document-light
	icon-dollar-filled: $icon-dollar-filled
	icon-dollar-light: $icon-dollar-light
	icon-donut-chart-filled: $icon-donut-chart-filled
	icon-donut-chart-light: $icon-donut-chart-light
	icon-download-filled: $icon-download-filled
	icon-download-light: $icon-download-light
	icon-drop-filled: $icon-drop-filled
	icon-drop-light: $icon-drop-light
	icon-dumbell-filled: $icon-dumbell-filled
	icon-dumbell-light: $icon-dumbell-light
	icon-edit-filled: $icon-edit-filled
	icon-edit-light: $icon-edit-light
	icon-euro-filled: $icon-euro-filled
	icon-euro-light: $icon-euro-light
	icon-event-add-filled: $icon-event-add-filled
	icon-event-add-light: $icon-event-add-light
	icon-event-scheduled-filled: $icon-event-scheduled-filled
	icon-event-scheduled-light: $icon-event-scheduled-light
	icon-expand-filled: $icon-expand-filled
	icon-expand-light: $icon-expand-light
	icon-eye-dropper-filled: $icon-eye-dropper-filled
	icon-eye-dropper-light: $icon-eye-dropper-light
	icon-flight-light-filled: $icon-flight-light-filled
	icon-flight-light-light: $icon-flight-light-light
	icon-facebook-light: $icon-facebook-light
	icon-fast-backward-filled: $icon-fast-backward-filled
	icon-fast-backward-light: $icon-fast-backward-light
	icon-fast-forward-filled: $icon-fast-forward-filled
	icon-fast-forward-light: $icon-fast-forward-light
	icon-figma-filled: $icon-figma-filled
	icon-figma-light: $icon-figma-light
	icon-file-add-filled: $icon-file-add-filled
	icon-file-add-light: $icon-file-add-light
	icon-file-filled: $icon-file-filled
	icon-file-light: $icon-file-light
	icon-files-filled: $icon-files-filled
	icon-files-light: $icon-files-light
	icon-film-filled: $icon-film-filled
	icon-film-light: $icon-film-light
	icon-filter-filled: $icon-filter-filled
	icon-filter-light: $icon-filter-light
	icon-fire-filled: $icon-fire-filled
	icon-fire-light: $icon-fire-light
	icon-flag-filled: $icon-flag-filled
	icon-flag-light: $icon-flag-light
	icon-flash-light-filled: $icon-flash-light-filled
	icon-flash-light-light: $icon-flash-light-light
	icon-folder-add-filled: $icon-folder-add-filled
	icon-folder-add-light: $icon-folder-add-light
	icon-folder-filled: $icon-folder-filled
	icon-folder-light: $icon-folder-light
	icon-frown-filled: $icon-frown-filled
	icon-frown-light: $icon-frown-light
	icon-fuel-filled: $icon-fuel-filled
	icon-fuel-light: $icon-fuel-light
	icon-gamepad-filled: $icon-gamepad-filled
	icon-gamepad-light: $icon-gamepad-light
	icon-gift-filled: $icon-gift-filled
	icon-gift-light: $icon-gift-light
	icon-grid-filled: $icon-grid-filled
	icon-grid-light: $icon-grid-light
	icon-group-filled: $icon-group-filled
	icon-group-light: $icon-group-light
	icon-growth-filled: $icon-growth-filled
	icon-growth-light: $icon-growth-light
	icon-halfstar-filled: $icon-halfstar-filled
	icon-halfstar-light: $icon-halfstar-light
	icon-hand-cursor-filled: $icon-hand-cursor-filled
	icon-hand-cursor-light: $icon-hand-cursor-light
	icon-hash-filled: $icon-hash-filled
	icon-hash-light: $icon-hash-light
	icon-headphone-filled: $icon-headphone-filled
	icon-headphone-light: $icon-headphone-light
	icon-heart-filled: $icon-heart-filled
	icon-heart-light: $icon-heart-light
	icon-help-filled: $icon-help-filled
	icon-help-light: $icon-help-light
	icon-hide-filled: $icon-hide-filled
	icon-hide-light: $icon-hide-light
	icon-history-filled: $icon-history-filled
	icon-history-light: $icon-history-light
	icon-home-filled: $icon-home-filled
	icon-home-light: $icon-home-light
	icon-hourglass-filled: $icon-hourglass-filled
	icon-hourglass-light: $icon-hourglass-light
	icon-hybrid-view-filled: $icon-hybrid-view-filled
	icon-hybrid-view-light: $icon-hybrid-view-light
	icon-image-filled: $icon-image-filled
	icon-image-light: $icon-image-light
	icon-inbox-filled: $icon-inbox-filled
	icon-inbox-light: $icon-inbox-light
	icon-info-filled: $icon-info-filled
	icon-info-light: $icon-info-light
	icon-instagram-filled: $icon-instagram-filled
	icon-instagram-light: $icon-instagram-light
	icon-internet-filled: $icon-internet-filled
	icon-internet-light: $icon-internet-light
	icon-italics-filled: $icon-italics-filled
	icon-italics-light: $icon-italics-light
	icon-justify-filled: $icon-justify-filled
	icon-justify-light: $icon-justify-light
	icon-key-filled: $icon-key-filled
	icon-key-light: $icon-key-light
	icon-keyboard-filled: $icon-keyboard-filled
	icon-keyboard-light: $icon-keyboard-light
	icon-keypad-filled: $icon-keypad-filled
	icon-keypad-light: $icon-keypad-light
	icon-laptop-filled: $icon-laptop-filled
	icon-laptop-light: $icon-laptop-light
	icon-laugh-filled: $icon-laugh-filled
	icon-laugh-light: $icon-laugh-light
	icon-layers-filled: $icon-layers-filled
	icon-layers-light: $icon-layers-light
	icon-layout-filled: $icon-layout-filled
	icon-layout-light: $icon-layout-light
	icon-leaderboard-filled: $icon-leaderboard-filled
	icon-leaderboard-light: $icon-leaderboard-light
	icon-left-align-filled: $icon-left-align-filled
	icon-left-align-light: $icon-left-align-light
	icon-line-chart-filled: $icon-line-chart-filled
	icon-line-chart-light: $icon-line-chart-light
	icon-link-filled: $icon-link-filled
	icon-link-light: $icon-link-light
	icon-linkedin-filled: $icon-linkedin-filled
	icon-linkedin-light: $icon-linkedin-light
	icon-list-filled: $icon-list-filled
	icon-list-light: $icon-list-light
	icon-live-filled: $icon-live-filled
	icon-live-light: $icon-live-light
	icon-loader-filled: $icon-loader-filled
	icon-loader-light: $icon-loader-light
	icon-location-filled: $icon-location-filled
	icon-location-light: $icon-location-light
	icon-lock-filled: $icon-lock-filled
	icon-lock-light: $icon-lock-light
	icon-login-filled: $icon-login-filled
	icon-login-light: $icon-login-light
	icon-logout-filled: $icon-logout-filled
	icon-logout-light: $icon-logout-light
	icon-mail-filled: $icon-mail-filled
	icon-mail-light: $icon-mail-light
	icon-map-filled: $icon-map-filled
	icon-map-light: $icon-map-light
	icon-medal-filled: $icon-medal-filled
	icon-medal-light: $icon-medal-light
	icon-meh-filled: $icon-meh-filled
	icon-meh-light: $icon-meh-light
	icon-menu-filled: $icon-menu-filled
	icon-menu-light: $icon-menu-light
	icon-message-filled: $icon-message-filled
	icon-message-light: $icon-message-light
	icon-messages-filled: $icon-messages-filled
	icon-messages-light: $icon-messages-light
	icon-mic-filled: $icon-mic-filled
	icon-mic-light: $icon-mic-light
	icon-mic-off-filled: $icon-mic-off-filled
	icon-mic-off-light: $icon-mic-off-light
	icon-mission-filled: $icon-mission-filled
	icon-mission-light: $icon-mission-light
	icon-mobile-filled: $icon-mobile-filled
	icon-mobile-light: $icon-mobile-light
	icon-moon-filled: $icon-moon-filled
	icon-moon-light: $icon-moon-light
	icon-more-horizontal-filled: $icon-more-horizontal-filled
	icon-more-horizontal-light: $icon-more-horizontal-light
	icon-more-vertical-filled: $icon-more-vertical-filled
	icon-more-vertical-light: $icon-more-vertical-light
	icon-mouse-filled: $icon-mouse-filled
	icon-mouse-light: $icon-mouse-light
	icon-multiselect-filled: $icon-multiselect-filled
	icon-multiselect-light: $icon-multiselect-light
	icon-music-filled: $icon-music-filled
	icon-music-light: $icon-music-light
	icon-new-filled: $icon-new-filled
	icon-new-light: $icon-new-light
	icon-next-filled: $icon-next-filled
	icon-next-light: $icon-next-light
	icon-note-filled: $icon-note-filled
	icon-note-light: $icon-note-light
	icon-notes-filled: $icon-notes-filled
	icon-notes-light: $icon-notes-light
	icon-notification-filled: $icon-notification-filled
	icon-notification-light: $icon-notification-light
	icon-notification-off-filled: $icon-notification-off-filled
	icon-notification-off-light: $icon-notification-off-light
	icon-open-filled: $icon-open-filled
	icon-open-light: $icon-open-light
	icon-paint-bucket-filled: $icon-paint-bucket-filled
	icon-paint-bucket-light: $icon-paint-bucket-light
	icon-paint-roller-filled: $icon-paint-roller-filled
	icon-paint-roller-light: $icon-paint-roller-light
	icon-paragraph-filled: $icon-paragraph-filled
	icon-paragraph-light: $icon-paragraph-light
	icon-pause-filled: $icon-pause-filled
	icon-pause-light: $icon-pause-light
	icon-payment-filled: $icon-payment-filled
	icon-payment-light: $icon-payment-light
	icon-pen-filled: $icon-pen-filled
	icon-pen-light: $icon-pen-light
	icon-percent-filled: $icon-percent-filled
	icon-percent-light: $icon-percent-light
	icon-person-filled: $icon-person-filled
	icon-person-light: $icon-person-light
	icon-phone-call-incoming-filled: $icon-phone-call-incoming-filled
	icon-phone-call-incoming-light: $icon-phone-call-incoming-light
	icon-phone-call-outgoing-filled: $icon-phone-call-outgoing-filled
	icon-phone-call-outgoing-light: $icon-phone-call-outgoing-light
	icon-phone-call-rejected-filled: $icon-phone-call-rejected-filled
	icon-phone-call-rejected-light: $icon-phone-call-rejected-light
	icon-phone-filled: $icon-phone-filled
	icon-phone-light: $icon-phone-light
	icon-photos-filled: $icon-photos-filled
	icon-photos-light: $icon-photos-light
	icon-pie-chart-filled: $icon-pie-chart-filled
	icon-pie-chart-light: $icon-pie-chart-light
	icon-pin-filled: $icon-pin-filled
	icon-pin-light: $icon-pin-light
	icon-pinterest-filled: $icon-pinterest-filled
	icon-pinterest-light: $icon-pinterest-light
	icon-pizza-filled: $icon-pizza-filled
	icon-pizza-light: $icon-pizza-light
	icon-play-circled-filled: $icon-play-circled-filled
	icon-play-circled-light: $icon-play-circled-light
	icon-play-filled: $icon-play-filled
	icon-play-light: $icon-play-light
	icon-playlist-filled: $icon-playlist-filled
	icon-playlist-light: $icon-playlist-light
	icon-power-filled: $icon-power-filled
	icon-power-light: $icon-power-light
	icon-presentation-filled: $icon-presentation-filled
	icon-presentation-light: $icon-presentation-light
	icon-previous-filled: $icon-previous-filled
	icon-previous-light: $icon-previous-light
	icon-printer-filled: $icon-printer-filled
	icon-printer-light: $icon-printer-light
	icon-privacy-filled: $icon-privacy-filled
	icon-privacy-light: $icon-privacy-light
	icon-profile-circled-filled: $icon-profile-circled-filled
	icon-profile-circled-light: $icon-profile-circled-light
	icon-promotion-filled: $icon-promotion-filled
	icon-promotion-light: $icon-promotion-light
	icon-puzzle-filled: $icon-puzzle-filled
	icon-puzzle-light: $icon-puzzle-light
	icon-rain-filled: $icon-rain-filled
	icon-rain-light: $icon-rain-light
	icon-receipt-filled: $icon-receipt-filled
	icon-receipt-light: $icon-receipt-light
	icon-redo-filled: $icon-redo-filled
	icon-redo-light: $icon-redo-light
	icon-reload-filled: $icon-reload-filled
	icon-reload-light: $icon-reload-light
	icon-remove-filled: $icon-remove-filled
	icon-remove-light: $icon-remove-light
	icon-repeat-filled: $icon-repeat-filled
	icon-repeat-light: $icon-repeat-light
	icon-right-align-filled: $icon-right-align-filled
	icon-right-align-light: $icon-right-align-light
	icon-rocket-filled: $icon-rocket-filled
	icon-rocket-light: $icon-rocket-light
	icon-ruler-filled: $icon-ruler-filled
	icon-ruler-light: $icon-ruler-light
	icon-rupee-filled: $icon-rupee-filled
	icon-rupee-light: $icon-rupee-light
	icon-sad-filled: $icon-sad-filled
	icon-sad-light: $icon-sad-light
	icon-safe-filled: $icon-safe-filled
	icon-safe-light: $icon-safe-light
	icon-save-filled: $icon-save-filled
	icon-save-light: $icon-save-light
	icon-scan-filled: $icon-scan-filled
	icon-scan-light: $icon-scan-light
	icon-schedule-filled: $icon-schedule-filled
	icon-schedule-light: $icon-schedule-light
	icon-scissors-filled: $icon-scissors-filled
	icon-scissors-light: $icon-scissors-light
	icon-search-filled: $icon-search-filled
	icon-search-light: $icon-search-light
	icon-send-filled: $icon-send-filled
	icon-send-light: $icon-send-light
	icon-server-filled: $icon-server-filled
	icon-server-light: $icon-server-light
	icon-settings-filled: $icon-settings-filled
	icon-settings-light: $icon-settings-light
	icon-share-filled: $icon-share-filled
	icon-share-light: $icon-share-light
	icon-shield-check-filled: $icon-shield-check-filled
	icon-shield-check-light: $icon-shield-check-light
	icon-shield-filled: $icon-shield-filled
	icon-shield-light: $icon-shield-light
	icon-ship-filled: $icon-ship-filled
	icon-ship-light: $icon-ship-light
	icon-shopping-bag-filled: $icon-shopping-bag-filled
	icon-shopping-bag-light: $icon-shopping-bag-light
	icon-show-filled: $icon-show-filled
	icon-show-light: $icon-show-light
	icon-shuffle-filled: $icon-shuffle-filled
	icon-shuffle-light: $icon-shuffle-light
	icon-size-filled: $icon-size-filled
	icon-size-light: $icon-size-light
	icon-slack-filled: $icon-slack-filled
	icon-slack-light: $icon-slack-light
	icon-smart-watch-filled: $icon-smart-watch-filled
	icon-smart-watch-light: $icon-smart-watch-light
	icon-smile-filled: $icon-smile-filled
	icon-smile-light: $icon-smile-light
	icon-snapchat-filled: $icon-snapchat-filled
	icon-snapchat-light: $icon-snapchat-light
	icon-snowflake-filled: $icon-snowflake-filled
	icon-snowflake-light: $icon-snowflake-light
	icon-sort-ascending-filled: $icon-sort-ascending-filled
	icon-sort-ascending-light: $icon-sort-ascending-light
	icon-sort-decending-filled: $icon-sort-decending-filled
	icon-sort-decending-light: $icon-sort-decending-light
	icon-sort-filled: $icon-sort-filled
	icon-sort-light: $icon-sort-light
	icon-spotify-filled: $icon-spotify-filled
	icon-spotify-light: $icon-spotify-light
	icon-spray-filled: $icon-spray-filled
	icon-spray-light: $icon-spray-light
	icon-star-filled: $icon-star-filled
	icon-star-light: $icon-star-light
	icon-stop-filled: $icon-stop-filled
	icon-stop-light: $icon-stop-light
	icon-store-filled: $icon-store-filled
	icon-store-light: $icon-store-light
	icon-sun-filled: $icon-sun-filled
	icon-sun-light: $icon-sun-light
	icon-sync-filled: $icon-sync-filled
	icon-sync-light: $icon-sync-light
	icon-table-tennis-filled: $icon-table-tennis-filled
	icon-table-tennis-light: $icon-table-tennis-light
	icon-tablet-filled: $icon-tablet-filled
	icon-tablet-light: $icon-tablet-light
	icon-tag-filled: $icon-tag-filled
	icon-tag-light: $icon-tag-light
	icon-target-filled: $icon-target-filled
	icon-target-light: $icon-target-light
	icon-thermometer-filled: $icon-thermometer-filled
	icon-thermometer-light: $icon-thermometer-light
	icon-thumbs-down-filled: $icon-thumbs-down-filled
	icon-thumbs-down-light: $icon-thumbs-down-light
	icon-thumbs-up-filled: $icon-thumbs-up-filled
	icon-thumbs-up-light: $icon-thumbs-up-light
	icon-ticket-filled: $icon-ticket-filled
	icon-ticket-light: $icon-ticket-light
	icon-tile-view-filled: $icon-tile-view-filled
	icon-tile-view-light: $icon-tile-view-light
	icon-timer-filled: $icon-timer-filled
	icon-timer-light: $icon-timer-light
	icon-toggle-off-filled: $icon-toggle-off-filled
	icon-toggle-off-light: $icon-toggle-off-light
	icon-toggle-on-filled: $icon-toggle-on-filled
	icon-toggle-on-light: $icon-toggle-on-light
	icon-tool-filled: $icon-tool-filled
	icon-tool-light: $icon-tool-light
	icon-train-filled: $icon-train-filled
	icon-train-light: $icon-train-light
	icon-trash-filled: $icon-trash-filled
	icon-trash-light: $icon-trash-light
	icon-trending-down-filled: $icon-trending-down-filled
	icon-trending-down-light: $icon-trending-down-light
	icon-trending-up-filled: $icon-trending-up-filled
	icon-trending-up-light: $icon-trending-up-light
	icon-trophy-filled: $icon-trophy-filled
	icon-trophy-light: $icon-trophy-light
	icon-truck-filled: $icon-truck-filled
	icon-truck-light: $icon-truck-light
	icon-tune-filled: $icon-tune-filled
	icon-tune-light: $icon-tune-light
	icon-tv-filled: $icon-tv-filled
	icon-tv-light: $icon-tv-light
	icon-twitch-filled: $icon-twitch-filled
	icon-twitch-light: $icon-twitch-light
	icon-twitter-filled: $icon-twitter-filled
	icon-twitter-light: $icon-twitter-light
	icon-umbrella-filled: $icon-umbrella-filled
	icon-umbrella-light: $icon-umbrella-light
	icon-underline-filled: $icon-underline-filled
	icon-underline-light: $icon-underline-light
	icon-undo-filled: $icon-undo-filled
	icon-undo-light: $icon-undo-light
	icon-unlock-filled: $icon-unlock-filled
	icon-unlock-light: $icon-unlock-light
	icon-update-filled: $icon-update-filled
	icon-update-light: $icon-update-light
	icon-upload-filled: $icon-upload-filled
	icon-upload-light: $icon-upload-light
	icon-utensils-filled: $icon-utensils-filled
	icon-utensils-light: $icon-utensils-light
	icon-vector-filled: $icon-vector-filled
	icon-vector-light: $icon-vector-light
	icon-video-off-filled: $icon-video-off-filled
	icon-video-off-light: $icon-video-off-light
	icon-video-recorder-filled: $icon-video-recorder-filled
	icon-video-recorder-light: $icon-video-recorder-light
	icon-volume-filled: $icon-volume-filled
	icon-volume-high-filled: $icon-volume-high-filled
	icon-volume-high-light: $icon-volume-high-light
	icon-volume-light: $icon-volume-light
	icon-volume-off-filled: $icon-volume-off-filled
	icon-volume-off-light: $icon-volume-off-light
	icon-wallet-filled: $icon-wallet-filled
	icon-wallet-light: $icon-wallet-light
	icon-warning-filled: $icon-warning-filled
	icon-warning-light: $icon-warning-light
	icon-watch-filled: $icon-watch-filled
	icon-watch-light: $icon-watch-light
	icon-web-development-filled: $icon-web-development-filled
	icon-web-development-light: $icon-web-development-light
	icon-wifi-filled: $icon-wifi-filled
	icon-wifi-light: $icon-wifi-light
	icon-wifi-off-filled: $icon-wifi-off-filled
	icon-wifi-off-light: $icon-wifi-off-light
	icon-wind-filled: $icon-wind-filled
	icon-wind-light: $icon-wind-light
	icon-window-filled: $icon-window-filled
	icon-window-light: $icon-window-light
	icon-wine-filled: $icon-wine-filled
	icon-wine-light: $icon-wine-light
	icon-youtube-filled: $icon-youtube-filled
	icon-youtube-light: $icon-youtube-light
	icon-zap-filled: $icon-zap-filled
	icon-zap-light: $icon-zap-light
	icon-zoom-in-filled: $icon-zoom-in-filled
	icon-zoom-in-light: $icon-zoom-in-light
	icon-zoom-out-filled: $icon-zoom-out-filled
	icon-zoom-out-light: $icon-zoom-out-light
	icon-apple-logo: $icon-apple-logo
	icon-facebook-simple-logo: $icon-facebook-simple-logo
	icon-instagram-logo: $icon-instagram-logo
	icon-twitter-logo: $icon-twitter-logo
	icon-down: $icon-down
	icon-left: $icon-left
	icon-right: $icon-right
	icon-up: $icon-up
	icon-google-logo: $icon-google-logo
	icon-facebook-filled: $icon-facebook-filled
