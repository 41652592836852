@import "_icons-variables.module"

@font-face
	font-family: 'trend-icons'
	src: url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/trend-icons/trend-icons.eot?a018h6')
	src: url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/trend-icons/trend-icons.eot?a018h6#iefix') format('embedded-opentype'), url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/trend-icons/trend-icons.ttf?a018h6') format('truetype'), url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/trend-icons/trend-icons.woff?a018h6') format('woff'), url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/trend-icons/trend-icons.svg?a018h6#trend-icons') format('svg')
	font-weight: normal
	font-style: normal
	font-display: block

i
	font-family: 'trend-icons' !important
	speak: never
	font-style: normal
	font-weight: normal
	font-variant: normal
	text-transform: none
	line-height: 1
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	span
		font-family: 'trend-icons' !important
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased
		-moz-osx-font-smoothing: grayscale
/**
/** Icon Sizes
/**

.icon-xxsmall
	font-size: 1rem

.icon-xsmall
	font-size: 1.5rem

.icon-small
	font-size: 2rem

.icon-medium
	font-size: 3rem

.icon-large
	font-size: 5rem

.icon-xlarge
	font-size: 6.5rem


/**
/** Icons
/**
=icon-default
	color: inherit

.icon-activity-filled
	&:before
		content: $icon-activity-filled
		+icon-default


.icon-activity-light
	&:before
		content: $icon-activity-light
		+icon-default


.icon-add-circled-filled
	&:before
		content: $icon-add-circled-filled
		+icon-default


.icon-add-circled-light
	&:before
		content: $icon-add-circled-light
		+icon-default


.icon-add-filled
	&:before
		content: $icon-add-filled
		+icon-default


.icon-add-light
	&:before
		content: $icon-add-light
		+icon-default


.icon-alarm-filled
	&:before
		content: $icon-alarm-filled
		+icon-default


.icon-alarm-light
	&:before
		content: $icon-alarm-light
		+icon-default


.icon-alert-circled-filled
	&:before
		content: $icon-alert-circled-filled
		+icon-default


.icon-alert-circled-light
	&:before
		content: $icon-alert-circled-light
		+icon-default


.icon-anchor-filled
	&:before
		content: $icon-anchor-filled
		+icon-default


.icon-anchor-light
	&:before
		content: $icon-anchor-light
		+icon-default


.icon-android-filled
	&:before
		content: $icon-android-filled
		+icon-default


.icon-android-light
	&:before
		content: $icon-android-light
		+icon-default


.icon-apple-filled
	&:before
		content: $icon-apple-filled
		+icon-default


.icon-apple-light
	&:before
		content: $icon-apple-light
		+icon-default


.icon-archive-filled
	&:before
		content: $icon-archive-filled
		+icon-default


.icon-archive-light
	&:before
		content: $icon-archive-light
		+icon-default


.icon-arrow-backward-filled
	&:before
		content: $icon-arrow-backward-filled
		+icon-default


.icon-arrow-backward-light
	&:before
		content: $icon-arrow-backward-light
		+icon-default


.icon-arrow-down-filled
	&:before
		content: $icon-arrow-down-filled
		+icon-default


.icon-arrow-down-light
	&:before
		content: $icon-arrow-down-light
		+icon-default


.icon-arrow-forward-filled
	&:before
		content: $icon-arrow-forward-filled
		+icon-default


.icon-arrow-forward-light
	&:before
		content: $icon-arrow-forward-light
		+icon-default


.icon-arrow-up-down-filled
	&:before
		content: $icon-arrow-up-down-filled
		+icon-default


.icon-arrow-up-down-light
	&:before
		content: $icon-arrow-up-down-light
		+icon-default


.icon-arrow-up-filled
	&:before
		content: $icon-arrow-up-filled
		+icon-default


.icon-arrow-up-light
	&:before
		content: $icon-arrow-up-light
		+icon-default


.icon-at-sign-filled
	&:before
		content: $icon-at-sign-filled
		+icon-default


.icon-at-sign-light
	&:before
		content: $icon-at-sign-light
		+icon-default


.icon-attachment-filled
	&:before
		content: $icon-attachment-filled
		+icon-default


.icon-attachment-light
	&:before
		content: $icon-attachment-light
		+icon-default


.icon-badge-filled
	&:before
		content: $icon-badge-filled
		+icon-default


.icon-badge-light
	&:before
		content: $icon-badge-light
		+icon-default


.icon-bank-filled
	&:before
		content: $icon-bank-filled
		+icon-default


.icon-bank-light
	&:before
		content: $icon-bank-light
		+icon-default


.icon-bar-chart-filled
	&:before
		content: $icon-bar-chart-filled
		+icon-default


.icon-bar-chart-light
	&:before
		content: $icon-bar-chart-light
		+icon-default


.icon-basket-filled
	&:before
		content: $icon-basket-filled
		+icon-default


.icon-basket-light
	&:before
		content: $icon-basket-light
		+icon-default


.icon-basketball-filled
	&:before
		content: $icon-basketball-filled
		+icon-default


.icon-basketball-light
	&:before
		content: $icon-basketball-light
		+icon-default


.icon-battery-charging-filled
	&:before
		content: $icon-battery-charging-filled
		+icon-default


.icon-battery-charging-light
	&:before
		content: $icon-battery-charging-light
		+icon-default


.icon-battery-filled
	&:before
		content: $icon-battery-filled
		+icon-default


.icon-battery-full-filled
	&:before
		content: $icon-battery-full-filled
		+icon-default


.icon-battery-full-light
	&:before
		content: $icon-battery-full-light
		+icon-default


.icon-battery-half-filled
	&:before
		content: $icon-battery-half-filled
		+icon-default


.icon-battery-half-light
	&:before
		content: $icon-battery-half-light
		+icon-default


.icon-battery-light
	&:before
		content: $icon-battery-light
		+icon-default


.icon-battery-low-filled
	&:before
		content: $icon-battery-low-filled
		+icon-default


.icon-battery-low-light
	&:before
		content: $icon-battery-low-light
		+icon-default


.icon-bluetooth-filled
	&:before
		content: $icon-bluetooth-filled
		+icon-default


.icon-bluetooth-light
	&:before
		content: $icon-bluetooth-light
		+icon-default


.icon-bluetooth-off-filled
	&:before
		content: $icon-bluetooth-off-filled
		+icon-default


.icon-bluetooth-off-light
	&:before
		content: $icon-bluetooth-off-light
		+icon-default


.icon-bold-filled
	&:before
		content: $icon-bold-filled
		+icon-default


.icon-bold-light
	&:before
		content: $icon-bold-light
		+icon-default


.icon-book-filled
	&:before
		content: $icon-book-filled
		+icon-default


.icon-book-light
	&:before
		content: $icon-book-light
		+icon-default


.icon-bookmark-filled
	&:before
		content: $icon-bookmark-filled
		+icon-default


.icon-bookmark-light
	&:before
		content: $icon-bookmark-light
		+icon-default


.icon-box-filled
	&:before
		content: $icon-box-filled
		+icon-default


.icon-box-light
	&:before
		content: $icon-box-light
		+icon-default


.icon-briefcase-filled
	&:before
		content: $icon-briefcase-filled
		+icon-default


.icon-briefcase-light
	&:before
		content: $icon-briefcase-light
		+icon-default


.icon-brush-filled
	&:before
		content: $icon-brush-filled
		+icon-default


.icon-brush-light
	&:before
		content: $icon-brush-light
		+icon-default


.icon-bug-filled
	&:before
		content: $icon-bug-filled
		+icon-default


.icon-bug-light
	&:before
		content: $icon-bug-light
		+icon-default


.icon-building-filled
	&:before
		content: $icon-building-filled
		+icon-default


.icon-building-light
	&:before
		content: $icon-building-light
		+icon-default


.icon-bulb-filled
	&:before
		content: $icon-bulb-filled
		+icon-default


.icon-bulb-light
	&:before
		content: $icon-bulb-light
		+icon-default


.icon-burger-filled
	&:before
		content: $icon-burger-filled
		+icon-default


.icon-burger-light
	&:before
		content: $icon-burger-light
		+icon-default


.icon-bus-filled
	&:before
		content: $icon-bus-filled
		+icon-default


.icon-bus-light
	&:before
		content: $icon-bus-light
		+icon-default


.icon-cake-filled
	&:before
		content: $icon-cake-filled
		+icon-default


.icon-cake-light
	&:before
		content: $icon-cake-light
		+icon-default


.icon-calculator-filled
	&:before
		content: $icon-calculator-filled
		+icon-default


.icon-calculator-light
	&:before
		content: $icon-calculator-light
		+icon-default


.icon-calendar-filled
	&:before
		content: $icon-calendar-filled
		+icon-default


.icon-calendar-light
	&:before
		content: $icon-calendar-light
		+icon-default


.icon-camera-filled
	&:before
		content: $icon-camera-filled
		+icon-default


.icon-camera-light
	&:before
		content: $icon-camera-light
		+icon-default


.icon-cart-filled
	&:before
		content: $icon-cart-filled
		+icon-default


.icon-cart-light
	&:before
		content: $icon-cart-light
		+icon-default


.icon-cast-filled
	&:before
		content: $icon-cast-filled
		+icon-default


.icon-cast-light
	&:before
		content: $icon-cast-light
		+icon-default


.icon-category-filled
	&:before
		content: $icon-category-filled
		+icon-default


.icon-category-light
	&:before
		content: $icon-category-light
		+icon-default


.icon-center-align-filled
	&:before
		content: $icon-center-align-filled
		+icon-default


.icon-center-align-light
	&:before
		content: $icon-center-align-light
		+icon-default


.icon-check-all-filled
	&:before
		content: $icon-check-all-filled
		+icon-default


.icon-check-all-light
	&:before
		content: $icon-check-all-light
		+icon-default


.icon-check-circled-filled-1
	&:before
		content: $icon-check-circled-filled-1
		+icon-default


.icon-check-circled-filled
	&:before
		content: $icon-check-circled-filled
		+icon-default


.icon-check-circled-light
	&:before
		content: $icon-check-circled-light
		+icon-default


.icon-check-filled
	&:before
		content: $icon-check-filled
		+icon-default


.icon-check-light
	&:before
		content: $icon-check-light
		+icon-default


.icon-checkbox-filled
	&:before
		content: $icon-checkbox-filled
		+icon-default


.icon-checkbox-light
	&:before
		content: $icon-checkbox-light
		+icon-default


.icon-checkbox-unchecked-filled
	&:before
		content: $icon-checkbox-unchecked-filled
		+icon-default


.icon-checkbox-unchecked-light
	&:before
		content: $icon-checkbox-unchecked-light
		+icon-default


.icon-chevron-backward-filled
	&:before
		content: $icon-chevron-backward-filled
		+icon-default


.icon-chevron-backward-light
	&:before
		content: $icon-chevron-backward-light
		+icon-default


.icon-chevron-double-backward-filled
	&:before
		content: $icon-chevron-double-backward-filled
		+icon-default


.icon-chevron-double-backward-light
	&:before
		content: $icon-chevron-double-backward-light
		+icon-default


.icon-chevron-double-down-filled
	&:before
		content: $icon-chevron-double-down-filled
		+icon-default


.icon-chevron-double-down-light
	&:before
		content: $icon-chevron-double-down-light
		+icon-default


.icon-chevron-double-forward-filled
	&:before
		content: $icon-chevron-double-forward-filled
		+icon-default


.icon-chevron-double-forward-light
	&:before
		content: $icon-chevron-double-forward-light
		+icon-default


.icon-chevron-double-up-filled
	&:before
		content: $icon-chevron-double-up-filled
		+icon-default


.icon-chevron-double-up-light
	&:before
		content: $icon-chevron-double-up-light
		+icon-default


.icon-chevron-down-filled
	&:before
		content: $icon-chevron-down-filled
		+icon-default


.icon-chevron-down-light
	&:before
		content: $icon-chevron-down-light
		+icon-default


.icon-chevron-forward-filled
	&:before
		content: $icon-chevron-forward-filled
		+icon-default


.icon-chevron-forward-light
	&:before
		content: $icon-chevron-forward-light
		+icon-default


.icon-chevron-up-filled
	&:before
		content: $icon-chevron-up-filled
		+icon-default


.icon-chevron-up-light
	&:before
		content: $icon-chevron-up-light
		+icon-default


.icon-chip-filled
	&:before
		content: $icon-chip-filled
		+icon-default


.icon-chip-light
	&:before
		content: $icon-chip-light
		+icon-default


.icon-chrome-filled
	&:before
		content: $icon-chrome-filled
		+icon-default


.icon-chrome-light
	&:before
		content: $icon-chrome-light
		+icon-default


.icon-clipboard-filled
	&:before
		content: $icon-clipboard-filled
		+icon-default


.icon-clipboard-light
	&:before
		content: $icon-clipboard-light
		+icon-default


.icon-clock-filled
	&:before
		content: $icon-clock-filled
		+icon-default


.icon-clock-light
	&:before
		content: $icon-clock-light
		+icon-default


.icon-close-circled-filled
	&:before
		content: $icon-close-circled-filled
		+icon-default


.icon-close-circled-light
	&:before
		content: $icon-close-circled-light
		+icon-default


.icon-close-filled
	&:before
		content: $icon-close-filled
		+icon-default


.icon-close-light-1
	&:before
		content: $icon-close-light-1
		+icon-default


.icon-close-light
	&:before
		content: $icon-close-light
		+icon-default


.icon-cloud-check-filled
	&:before
		content: $icon-cloud-check-filled
		+icon-default


.icon-cloud-check-light
	&:before
		content: $icon-cloud-check-light
		+icon-default


.icon-cloud-download-filled
	&:before
		content: $icon-cloud-download-filled
		+icon-default


.icon-cloud-download-light
	&:before
		content: $icon-cloud-download-light
		+icon-default


.icon-cloud-filled
	&:before
		content: $icon-cloud-filled
		+icon-default


.icon-cloud-light
	&:before
		content: $icon-cloud-light
		+icon-default


.icon-cloud-off-filled
	&:before
		content: $icon-cloud-off-filled
		+icon-default


.icon-cloud-off-light
	&:before
		content: $icon-cloud-off-light
		+icon-default


.icon-cloud-upload-filled
	&:before
		content: $icon-cloud-upload-filled
		+icon-default


.icon-cloud-upload-light
	&:before
		content: $icon-cloud-upload-light
		+icon-default


.icon-code-filled
	&:before
		content: $icon-code-filled
		+icon-default


.icon-code-light
	&:before
		content: $icon-code-light
		+icon-default


.icon-coffee-filled
	&:before
		content: $icon-coffee-filled
		+icon-default


.icon-coffee-light
	&:before
		content: $icon-coffee-light
		+icon-default


.icon-collapse-filled
	&:before
		content: $icon-collapse-filled
		+icon-default


.icon-collapse-light
	&:before
		content: $icon-collapse-light
		+icon-default


.icon-color-palette-filled
	&:before
		content: $icon-color-palette-filled
		+icon-default


.icon-color-palette-light
	&:before
		content: $icon-color-palette-light
		+icon-default


.icon-command-filled
	&:before
		content: $icon-command-filled
		+icon-default


.icon-command-light
	&:before
		content: $icon-command-light
		+icon-default


.icon-compass-filled
	&:before
		content: $icon-compass-filled
		+icon-default


.icon-compass-light
	&:before
		content: $icon-compass-light
		+icon-default


.icon-copy-filled
	&:before
		content: $icon-copy-filled
		+icon-default


.icon-copy-light
	&:before
		content: $icon-copy-light
		+icon-default


.icon-crop-filled
	&:before
		content: $icon-crop-filled
		+icon-default


.icon-crop-light
	&:before
		content: $icon-crop-light
		+icon-default


.icon-crosshairs-filled
	&:before
		content: $icon-crosshairs-filled
		+icon-default


.icon-crosshairs-light
	&:before
		content: $icon-crosshairs-light
		+icon-default


.icon-cursor-filled
	&:before
		content: $icon-cursor-filled
		+icon-default


.icon-cursor-light
	&:before
		content: $icon-cursor-light
		+icon-default


.icon-cycling-filled
	&:before
		content: $icon-cycling-filled
		+icon-default


.icon-cycling-light
	&:before
		content: $icon-cycling-light
		+icon-default


.icon-dashboard-filled
	&:before
		content: $icon-dashboard-filled
		+icon-default


.icon-dashboard-light
	&:before
		content: $icon-dashboard-light
		+icon-default


.icon-database-filled
	&:before
		content: $icon-database-filled
		+icon-default


.icon-database-light
	&:before
		content: $icon-database-light
		+icon-default


.icon-design-filled
	&:before
		content: $icon-design-filled
		+icon-default


.icon-design-light
	&:before
		content: $icon-design-light
		+icon-default


.icon-diamond-filled
	&:before
		content: $icon-diamond-filled
		+icon-default


.icon-diamond-light
	&:before
		content: $icon-diamond-light
		+icon-default


.icon-document-filled
	&:before
		content: $icon-document-filled
		+icon-default


.icon-document-light
	&:before
		content: $icon-document-light
		+icon-default


.icon-dollar-filled
	&:before
		content: $icon-dollar-filled
		+icon-default


.icon-dollar-light
	&:before
		content: $icon-dollar-light
		+icon-default


.icon-donut-chart-filled
	&:before
		content: $icon-donut-chart-filled
		+icon-default


.icon-donut-chart-light
	&:before
		content: $icon-donut-chart-light
		+icon-default


.icon-download-filled
	&:before
		content: $icon-download-filled
		+icon-default


.icon-download-light
	&:before
		content: $icon-download-light
		+icon-default


.icon-drop-filled
	&:before
		content: $icon-drop-filled
		+icon-default


.icon-drop-light
	&:before
		content: $icon-drop-light
		+icon-default


.icon-dumbell-filled
	&:before
		content: $icon-dumbell-filled
		+icon-default


.icon-dumbell-light
	&:before
		content: $icon-dumbell-light
		+icon-default


.icon-edit-filled
	&:before
		content: $icon-edit-filled
		+icon-default


.icon-edit-light
	&:before
		content: $icon-edit-light
		+icon-default


.icon-euro-filled
	&:before
		content: $icon-euro-filled
		+icon-default


.icon-euro-light
	&:before
		content: $icon-euro-light
		+icon-default


.icon-event-add-filled
	&:before
		content: $icon-event-add-filled
		+icon-default


.icon-event-add-light
	&:before
		content: $icon-event-add-light
		+icon-default


.icon-event-scheduled-filled
	&:before
		content: $icon-event-scheduled-filled
		+icon-default


.icon-event-scheduled-light
	&:before
		content: $icon-event-scheduled-light
		+icon-default


.icon-expand-filled
	&:before
		content: $icon-expand-filled
		+icon-default


.icon-expand-light
	&:before
		content: $icon-expand-light
		+icon-default


.icon-eye-dropper-filled
	&:before
		content: $icon-eye-dropper-filled
		+icon-default


.icon-eye-dropper-light
	&:before
		content: $icon-eye-dropper-light
		+icon-default


.icon-flight-light-filled
	&:before
		content: $icon-flight-light-filled
		+icon-default


.icon-flight-light-light
	&:before
		content: $icon-flight-light-light
		+icon-default


.icon-facebook-filled
	&:before
		content: "\e9c8"
		color: rgb(25, 119, 243)
	&:after
		content: "\e9c9"
		margin-left: -1em
		color: rgb(254, 254, 254)

.icon-facebook-light
	&:before
		content: $icon-facebook-light
		+icon-default


.icon-fast-backward-filled
	&:before
		content: $icon-fast-backward-filled
		+icon-default


.icon-fast-backward-light
	&:before
		content: $icon-fast-backward-light
		+icon-default


.icon-fast-forward-filled
	&:before
		content: $icon-fast-forward-filled
		+icon-default


.icon-fast-forward-light
	&:before
		content: $icon-fast-forward-light
		+icon-default


.icon-figma-filled
	&:before
		content: $icon-figma-filled
		+icon-default


.icon-figma-light
	&:before
		content: $icon-figma-light
		+icon-default


.icon-file-add-filled
	&:before
		content: $icon-file-add-filled
		+icon-default


.icon-file-add-light
	&:before
		content: $icon-file-add-light
		+icon-default


.icon-file-filled
	&:before
		content: $icon-file-filled
		+icon-default


.icon-file-light
	&:before
		content: $icon-file-light
		+icon-default


.icon-files-filled
	&:before
		content: $icon-files-filled
		+icon-default


.icon-files-light
	&:before
		content: $icon-files-light
		+icon-default


.icon-film-filled
	&:before
		content: $icon-film-filled
		+icon-default


.icon-film-light
	&:before
		content: $icon-film-light
		+icon-default


.icon-filter-filled
	&:before
		content: $icon-filter-filled
		+icon-default


.icon-filter-light
	&:before
		content: $icon-filter-light
		+icon-default


.icon-fire-filled
	&:before
		content: $icon-fire-filled
		+icon-default


.icon-fire-light
	&:before
		content: $icon-fire-light
		+icon-default


.icon-flag-filled
	&:before
		content: $icon-flag-filled
		+icon-default


.icon-flag-light
	&:before
		content: $icon-flag-light
		+icon-default


.icon-flash-light-filled
	&:before
		content: $icon-flash-light-filled
		+icon-default


.icon-flash-light-light
	&:before
		content: $icon-flash-light-light
		+icon-default


.icon-folder-add-filled
	&:before
		content: $icon-folder-add-filled
		+icon-default


.icon-folder-add-light
	&:before
		content: $icon-folder-add-light
		+icon-default


.icon-folder-filled
	&:before
		content: $icon-folder-filled
		+icon-default


.icon-folder-light
	&:before
		content: $icon-folder-light
		+icon-default


.icon-frown-filled
	&:before
		content: $icon-frown-filled
		+icon-default


.icon-frown-light
	&:before
		content: $icon-frown-light
		+icon-default


.icon-fuel-filled
	&:before
		content: $icon-fuel-filled
		+icon-default


.icon-fuel-light
	&:before
		content: $icon-fuel-light
		+icon-default


.icon-gamepad-filled
	&:before
		content: $icon-gamepad-filled
		+icon-default


.icon-gamepad-light
	&:before
		content: $icon-gamepad-light
		+icon-default


.icon-gift-filled
	&:before
		content: $icon-gift-filled
		+icon-default


.icon-gift-light
	&:before
		content: $icon-gift-light
		+icon-default


.icon-grid-filled
	&:before
		content: $icon-grid-filled
		+icon-default


.icon-grid-light
	&:before
		content: $icon-grid-light
		+icon-default


.icon-group-filled
	&:before
		content: $icon-group-filled
		+icon-default


.icon-group-light
	&:before
		content: $icon-group-light
		+icon-default


.icon-growth-filled
	&:before
		content: $icon-growth-filled
		+icon-default


.icon-growth-light
	&:before
		content: $icon-growth-light
		+icon-default


.icon-halfstar-filled
	&:before
		content: $icon-halfstar-filled
		+icon-default


.icon-halfstar-light
	&:before
		content: $icon-halfstar-light
		+icon-default


.icon-hand-cursor-filled
	&:before
		content: $icon-hand-cursor-filled
		+icon-default


.icon-hand-cursor-light
	&:before
		content: $icon-hand-cursor-light
		+icon-default


.icon-hash-filled
	&:before
		content: $icon-hash-filled
		+icon-default


.icon-hash-light
	&:before
		content: $icon-hash-light
		+icon-default


.icon-headphone-filled
	&:before
		content: $icon-headphone-filled
		+icon-default


.icon-headphone-light
	&:before
		content: $icon-headphone-light
		+icon-default


.icon-heart-filled
	&:before
		content: $icon-heart-filled
		+icon-default


.icon-heart-light
	&:before
		content: $icon-heart-light
		+icon-default


.icon-help-filled
	&:before
		content: $icon-help-filled
		+icon-default


.icon-help-light
	&:before
		content: $icon-help-light
		+icon-default


.icon-hide-filled
	&:before
		content: $icon-hide-filled
		+icon-default


.icon-hide-light
	&:before
		content: $icon-hide-light
		+icon-default


.icon-history-filled
	&:before
		content: $icon-history-filled
		+icon-default


.icon-history-light
	&:before
		content: $icon-history-light
		+icon-default


.icon-home-filled
	&:before
		content: $icon-home-filled
		+icon-default


.icon-home-light
	&:before
		content: $icon-home-light
		+icon-default


.icon-hourglass-filled
	&:before
		content: $icon-hourglass-filled
		+icon-default


.icon-hourglass-light
	&:before
		content: $icon-hourglass-light
		+icon-default


.icon-hybrid-view-filled
	&:before
		content: $icon-hybrid-view-filled
		+icon-default


.icon-hybrid-view-light
	&:before
		content: $icon-hybrid-view-light
		+icon-default


.icon-image-filled
	&:before
		content: $icon-image-filled
		+icon-default


.icon-image-light
	&:before
		content: $icon-image-light
		+icon-default


.icon-inbox-filled
	&:before
		content: $icon-inbox-filled
		+icon-default


.icon-inbox-light
	&:before
		content: $icon-inbox-light
		+icon-default


.icon-info-filled
	&:before
		content: $icon-info-filled
		+icon-default


.icon-info-light
	&:before
		content: $icon-info-light
		+icon-default


.icon-instagram-filled
	&:before
		content: $icon-instagram-filled
		+icon-default


.icon-instagram-light
	&:before
		content: $icon-instagram-light
		+icon-default


.icon-internet-filled
	&:before
		content: $icon-internet-filled
		+icon-default


.icon-internet-light
	&:before
		content: $icon-internet-light
		+icon-default


.icon-italics-filled
	&:before
		content: $icon-italics-filled
		+icon-default


.icon-italics-light
	&:before
		content: $icon-italics-light
		+icon-default


.icon-justify-filled
	&:before
		content: $icon-justify-filled
		+icon-default


.icon-justify-light
	&:before
		content: $icon-justify-light
		+icon-default


.icon-key-filled
	&:before
		content: $icon-key-filled
		+icon-default


.icon-key-light
	&:before
		content: $icon-key-light


.icon-keyboard-filled
	&:before
		content: $icon-keyboard-filled
		+icon-default


.icon-keyboard-light
	&:before
		content: $icon-keyboard-light
		+icon-default


.icon-keypad-filled
	&:before
		content: $icon-keypad-filled
		+icon-default


.icon-keypad-light
	&:before
		content: $icon-keypad-light
		+icon-default


.icon-laptop-filled
	&:before
		content: $icon-laptop-filled
		+icon-default


.icon-laptop-light
	&:before
		content: $icon-laptop-light
		+icon-default


.icon-laugh-filled
	&:before
		content: $icon-laugh-filled
		+icon-default


.icon-laugh-light
	&:before
		content: $icon-laugh-light
		+icon-default


.icon-layers-filled
	&:before
		content: $icon-layers-filled
		+icon-default


.icon-layers-light
	&:before
		content: $icon-layers-light
		+icon-default


.icon-layout-filled
	&:before
		content: $icon-layout-filled
		+icon-default


.icon-layout-light
	&:before
		content: $icon-layout-light
		+icon-default


.icon-leaderboard-filled
	&:before
		content: $icon-leaderboard-filled
		+icon-default


.icon-leaderboard-light
	&:before
		content: $icon-leaderboard-light
		+icon-default


.icon-left-align-filled
	&:before
		content: $icon-left-align-filled
		+icon-default


.icon-left-align-light
	&:before
		content: $icon-left-align-light
		+icon-default


.icon-line-chart-filled
	&:before
		content: $icon-line-chart-filled
		+icon-default


.icon-line-chart-light
	&:before
		content: $icon-line-chart-light
		+icon-default


.icon-link-filled
	&:before
		content: $icon-link-filled
		+icon-default


.icon-link-light
	&:before
		content: $icon-link-light
		+icon-default


.icon-linkedin-filled
	&:before
		content: $icon-linkedin-filled
		+icon-default


.icon-linkedin-light
	&:before
		content: $icon-linkedin-light
		+icon-default


.icon-list-filled
	&:before
		content: $icon-list-filled
		+icon-default


.icon-list-light
	&:before
		content: $icon-list-light
		+icon-default


.icon-live-filled
	&:before
		content: $icon-live-filled
		+icon-default


.icon-live-light
	&:before
		content: $icon-live-light
		+icon-default


.icon-loader-filled
	&:before
		content: $icon-loader-filled
		+icon-default


.icon-loader-light
	&:before
		content: $icon-loader-light
		+icon-default


.icon-location-filled
	&:before
		content: $icon-location-filled
		+icon-default


.icon-location-light
	&:before
		content: $icon-location-light
		+icon-default


.icon-lock-filled
	&:before
		content: $icon-lock-filled
		+icon-default


.icon-lock-light
	&:before
		content: $icon-lock-light
		+icon-default


.icon-login-filled
	&:before
		content: $icon-login-filled
		+icon-default


.icon-login-light
	&:before
		content: $icon-login-light
		+icon-default


.icon-logout-filled
	&:before
		content: $icon-logout-filled
		+icon-default


.icon-logout-light
	&:before
		content: $icon-logout-light
		+icon-default


.icon-mail-filled
	&:before
		content: $icon-mail-filled
		+icon-default


.icon-mail-light
	&:before
		content: $icon-mail-light
		+icon-default


.icon-map-filled
	&:before
		content: $icon-map-filled
		+icon-default


.icon-map-light
	&:before
		content: $icon-map-light
		+icon-default


.icon-medal-filled
	&:before
		content: $icon-medal-filled
		+icon-default


.icon-medal-light
	&:before
		content: $icon-medal-light
		+icon-default


.icon-meh-filled
	&:before
		content: $icon-meh-filled
		+icon-default


.icon-meh-light
	&:before
		content: $icon-meh-light
		+icon-default


.icon-menu-filled
	&:before
		content: $icon-menu-filled
		+icon-default


.icon-menu-light
	&:before
		content: $icon-menu-light
		+icon-default


.icon-message-filled
	&:before
		content: $icon-message-filled
		+icon-default


.icon-message-light
	&:before
		content: $icon-message-light
		+icon-default


.icon-messages-filled
	&:before
		content: $icon-messages-filled
		+icon-default


.icon-messages-light
	&:before
		content: $icon-messages-light
		+icon-default


.icon-mic-filled
	&:before
		content: $icon-mic-filled
		+icon-default


.icon-mic-light
	&:before
		content: $icon-mic-light
		+icon-default


.icon-mic-off-filled
	&:before
		content: $icon-mic-off-filled
		+icon-default


.icon-mic-off-light
	&:before
		content: $icon-mic-off-light
		+icon-default


.icon-mission-filled
	&:before
		content: $icon-mission-filled
		+icon-default


.icon-mission-light
	&:before
		content: $icon-mission-light
		+icon-default


.icon-mobile-filled
	&:before
		content: $icon-mobile-filled
		+icon-default


.icon-mobile-light
	&:before
		content: $icon-mobile-light
		+icon-default


.icon-moon-filled
	&:before
		content: $icon-moon-filled
		+icon-default


.icon-moon-light
	&:before
		content: $icon-moon-light
		+icon-default


.icon-more-horizontal-filled
	&:before
		content: $icon-more-horizontal-filled
		+icon-default


.icon-more-horizontal-light
	&:before
		content: $icon-more-horizontal-light
		+icon-default


.icon-more-vertical-filled
	&:before
		content: $icon-more-vertical-filled
		+icon-default


.icon-more-vertical-light
	&:before
		content: $icon-more-vertical-light
		+icon-default


.icon-mouse-filled
	&:before
		content: $icon-mouse-filled
		+icon-default


.icon-mouse-light
	&:before
		content: $icon-mouse-light
		+icon-default


.icon-multiselect-filled
	&:before
		content: $icon-multiselect-filled
		+icon-default


.icon-multiselect-light
	&:before
		content: $icon-multiselect-light
		+icon-default


.icon-music-filled
	&:before
		content: $icon-music-filled
		+icon-default


.icon-music-light
	&:before
		content: $icon-music-light
		+icon-default


.icon-new-filled
	&:before
		content: $icon-new-filled
		+icon-default


.icon-new-light
	&:before
		content: $icon-new-light
		+icon-default


.icon-next-filled
	&:before
		content: $icon-next-filled
		+icon-default


.icon-next-light
	&:before
		content: $icon-next-light
		+icon-default


.icon-note-filled
	&:before
		content: $icon-note-filled
		+icon-default


.icon-note-light
	&:before
		content: $icon-note-light
		+icon-default


.icon-notes-filled
	&:before
		content: $icon-notes-filled
		+icon-default


.icon-notes-light
	&:before
		content: $icon-notes-light
		+icon-default


.icon-notification-filled
	&:before
		content: $icon-notification-filled
		+icon-default


.icon-notification-light
	&:before
		content: $icon-notification-light
		+icon-default


.icon-notification-off-filled
	&:before
		content: $icon-notification-off-filled
		+icon-default


.icon-notification-off-light
	&:before
		content: $icon-notification-off-light
		+icon-default


.icon-open-filled
	&:before
		content: $icon-open-filled
		+icon-default


.icon-open-light
	&:before
		content: $icon-open-light
		+icon-default


.icon-paint-bucket-filled
	&:before
		content: $icon-paint-bucket-filled
		+icon-default


.icon-paint-bucket-light
	&:before
		content: $icon-paint-bucket-light
		+icon-default


.icon-paint-roller-filled
	&:before
		content: $icon-paint-roller-filled
		+icon-default


.icon-paint-roller-light
	&:before
		content: $icon-paint-roller-light
		+icon-default


.icon-paragraph-filled
	&:before
		content: $icon-paragraph-filled
		+icon-default


.icon-paragraph-light
	&:before
		content: $icon-paragraph-light
		+icon-default


.icon-pause-filled
	&:before
		content: $icon-pause-filled
		+icon-default


.icon-pause-light
	&:before
		content: $icon-pause-light
		+icon-default


.icon-payment-filled
	&:before
		content: $icon-payment-filled
		+icon-default


.icon-payment-light
	&:before
		content: $icon-payment-light
		+icon-default


.icon-pen-filled
	&:before
		content: $icon-pen-filled
		+icon-default


.icon-pen-light
	&:before
		content: $icon-pen-light
		+icon-default


.icon-percent-filled
	&:before
		content: $icon-percent-filled
		+icon-default


.icon-percent-light
	&:before
		content: $icon-percent-light
		+icon-default


.icon-person-filled
	&:before
		content: $icon-person-filled
		+icon-default


.icon-person-light
	&:before
		content: $icon-person-light
		+icon-default


.icon-phone-call-incoming-filled
	&:before
		content: $icon-phone-call-incoming-filled
		+icon-default


.icon-phone-call-incoming-light
	&:before
		content: $icon-phone-call-incoming-light
		+icon-default


.icon-phone-call-outgoing-filled
	&:before
		content: $icon-phone-call-outgoing-filled
		+icon-default


.icon-phone-call-outgoing-light
	&:before
		content: $icon-phone-call-outgoing-light
		+icon-default


.icon-phone-call-rejected-filled
	&:before
		content: $icon-phone-call-rejected-filled
		+icon-default


.icon-phone-call-rejected-light
	&:before
		content: $icon-phone-call-rejected-light
		+icon-default


.icon-phone-filled
	&:before
		content: $icon-phone-filled
		+icon-default


.icon-phone-light
	&:before
		content: $icon-phone-light
		+icon-default


.icon-photos-filled
	&:before
		content: $icon-photos-filled
		+icon-default


.icon-photos-light
	&:before
		content: $icon-photos-light
		+icon-default


.icon-pie-chart-filled
	&:before
		content: $icon-pie-chart-filled
		+icon-default


.icon-pie-chart-light
	&:before
		content: $icon-pie-chart-light
		+icon-default


.icon-pin-filled
	&:before
		content: $icon-pin-filled
		+icon-default


.icon-pin-light
	&:before
		content: $icon-pin-light
		+icon-default


.icon-pinterest-filled
	&:before
		content: $icon-pinterest-filled
		+icon-default


.icon-pinterest-light
	&:before
		content: $icon-pinterest-light
		+icon-default


.icon-pizza-filled
	&:before
		content: $icon-pizza-filled
		+icon-default


.icon-pizza-light
	&:before
		content: $icon-pizza-light
		+icon-default


.icon-play-circled-filled
	&:before
		content: $icon-play-circled-filled
		+icon-default


.icon-play-circled-light
	&:before
		content: $icon-play-circled-light
		+icon-default


.icon-play-filled
	&:before
		content: $icon-play-filled
		+icon-default


.icon-play-light
	&:before
		content: $icon-play-light
		+icon-default


.icon-playlist-filled
	&:before
		content: $icon-playlist-filled
		+icon-default


.icon-playlist-light
	&:before
		content: $icon-playlist-light
		+icon-default


.icon-power-filled
	&:before
		content: $icon-power-filled
		+icon-default


.icon-power-light
	&:before
		content: $icon-power-light
		+icon-default


.icon-presentation-filled
	&:before
		content: $icon-presentation-filled
		+icon-default


.icon-presentation-light
	&:before
		content: $icon-presentation-light
		+icon-default


.icon-previous-filled
	&:before
		content: $icon-previous-filled
		+icon-default


.icon-previous-light
	&:before
		content: $icon-previous-light
		+icon-default


.icon-printer-filled
	&:before
		content: $icon-printer-filled
		+icon-default


.icon-printer-light
	&:before
		content: $icon-printer-light
		+icon-default


.icon-privacy-filled
	&:before
		content: $icon-privacy-filled
		+icon-default


.icon-privacy-light
	&:before
		content: $icon-privacy-light
		+icon-default


.icon-profile-circled-filled
	&:before
		content: $icon-profile-circled-filled
		+icon-default


.icon-profile-circled-light
	&:before
		content: $icon-profile-circled-light
		+icon-default


.icon-promotion-filled
	&:before
		content: $icon-promotion-filled
		+icon-default


.icon-promotion-light
	&:before
		content: $icon-promotion-light
		+icon-default


.icon-puzzle-filled
	&:before
		content: $icon-puzzle-filled
		+icon-default


.icon-puzzle-light
	&:before
		content: $icon-puzzle-light
		+icon-default


.icon-rain-filled
	&:before
		content: $icon-rain-filled
		+icon-default


.icon-rain-light
	&:before
		content: $icon-rain-light
		+icon-default


.icon-receipt-filled
	&:before
		content: $icon-receipt-filled
		+icon-default


.icon-receipt-light
	&:before
		content: $icon-receipt-light
		+icon-default


.icon-redo-filled
	&:before
		content: $icon-redo-filled
		+icon-default


.icon-redo-light
	&:before
		content: $icon-redo-light
		+icon-default


.icon-reload-filled
	&:before
		content: $icon-reload-filled
		+icon-default


.icon-reload-light
	&:before
		content: $icon-reload-light
		+icon-default


.icon-remove-filled
	&:before
		content: $icon-remove-filled
		+icon-default


.icon-remove-light
	&:before
		content: $icon-remove-light
		+icon-default


.icon-repeat-filled
	&:before
		content: $icon-repeat-filled
		+icon-default


.icon-repeat-light
	&:before
		content: $icon-repeat-light
		+icon-default


.icon-right-align-filled
	&:before
		content: $icon-right-align-filled
		+icon-default


.icon-right-align-light
	&:before
		content: $icon-right-align-light
		+icon-default


.icon-rocket-filled
	&:before
		content: $icon-rocket-filled


.icon-rocket-light
	&:before
		content: $icon-rocket-light
		+icon-default


.icon-ruler-filled
	&:before
		content: $icon-ruler-filled
		+icon-default


.icon-ruler-light
	&:before
		content: $icon-ruler-light
		+icon-default


.icon-rupee-filled
	&:before
		content: $icon-rupee-filled
		+icon-default


.icon-rupee-light
	&:before
		content: $icon-rupee-light
		+icon-default


.icon-sad-filled
	&:before
		content: $icon-sad-filled
		+icon-default


.icon-sad-light
	&:before
		content: $icon-sad-light
		+icon-default


.icon-safe-filled
	&:before
		content: $icon-safe-filled
		+icon-default


.icon-safe-light
	&:before
		content: $icon-safe-light
		+icon-default


.icon-save-filled
	&:before
		content: $icon-save-filled
		+icon-default


.icon-save-light
	&:before
		content: $icon-save-light
		+icon-default


.icon-scan-filled
	&:before
		content: $icon-scan-filled
		+icon-default


.icon-scan-light
	&:before
		content: $icon-scan-light
		+icon-default


.icon-schedule-filled
	&:before
		content: $icon-schedule-filled
		+icon-default


.icon-schedule-light
	&:before
		content: $icon-schedule-light
		+icon-default


.icon-scissors-filled
	&:before
		content: $icon-scissors-filled
		+icon-default


.icon-scissors-light
	&:before
		content: $icon-scissors-light
		+icon-default


.icon-search-filled
	&:before
		content: $icon-search-filled
		+icon-default


.icon-search-light
	&:before
		content: $icon-search-light
		+icon-default


.icon-send-filled
	&:before
		content: $icon-send-filled
		+icon-default


.icon-send-light
	&:before
		content: $icon-send-light
		+icon-default


.icon-server-filled
	&:before
		content: $icon-server-filled
		+icon-default


.icon-server-light
	&:before
		content: $icon-server-light
		+icon-default


.icon-settings-filled
	&:before
		content: $icon-settings-filled
		+icon-default


.icon-settings-light
	&:before
		content: $icon-settings-light
		+icon-default


.icon-share-filled
	&:before
		content: $icon-share-filled
		+icon-default


.icon-share-light
	&:before
		content: $icon-share-light
		+icon-default


.icon-shield-check-filled
	&:before
		content: $icon-shield-check-filled
		+icon-default


.icon-shield-check-light
	&:before
		content: $icon-shield-check-light
		+icon-default


.icon-shield-filled
	&:before
		content: $icon-shield-filled
		+icon-default


.icon-shield-light
	&:before
		content: $icon-shield-light
		+icon-default


.icon-ship-filled
	&:before
		content: $icon-ship-filled
		+icon-default


.icon-ship-light
	&:before
		content: $icon-ship-light
		+icon-default


.icon-shopping-bag-filled
	&:before
		content: $icon-shopping-bag-filled
		+icon-default


.icon-shopping-bag-light
	&:before
		content: $icon-shopping-bag-light
		+icon-default


.icon-show-filled
	&:before
		content: $icon-show-filled
		+icon-default


.icon-show-light
	&:before
		content: $icon-show-light
		+icon-default


.icon-shuffle-filled
	&:before
		content: $icon-shuffle-filled
		+icon-default


.icon-shuffle-light
	&:before
		content: $icon-shuffle-light
		+icon-default


.icon-size-filled
	&:before
		content: $icon-size-filled
		+icon-default


.icon-size-light
	&:before
		content: $icon-size-light
		+icon-default


.icon-slack-filled
	&:before
		content: $icon-slack-filled
		+icon-default


.icon-slack-light
	&:before
		content: $icon-slack-light
		+icon-default


.icon-smart-watch-filled
	&:before
		content: $icon-smart-watch-filled
		+icon-default


.icon-smart-watch-light
	&:before
		content: $icon-smart-watch-light
		+icon-default


.icon-smile-filled
	&:before
		content: $icon-smile-filled
		+icon-default


.icon-smile-light
	&:before
		content: $icon-smile-light
		+icon-default


.icon-snapchat-filled
	&:before
		content: $icon-snapchat-filled
		+icon-default


.icon-snapchat-light
	&:before
		content: $icon-snapchat-light
		+icon-default


.icon-snowflake-filled
	&:before
		content: $icon-snowflake-filled
		+icon-default


.icon-snowflake-light
	&:before
		content: $icon-snowflake-light
		+icon-default


.icon-sort-ascending-filled
	&:before
		content: $icon-sort-ascending-filled
		+icon-default


.icon-sort-ascending-light
	&:before
		content: $icon-sort-ascending-light
		+icon-default


.icon-sort-decending-filled
	&:before
		content: $icon-sort-decending-filled
		+icon-default


.icon-sort-decending-light
	&:before
		content: $icon-sort-decending-light
		+icon-default


.icon-sort-filled
	&:before
		content: $icon-sort-filled
		+icon-default


.icon-sort-light
	&:before
		content: $icon-sort-light
		+icon-default


.icon-spotify-filled
	&:before
		content: $icon-spotify-filled
		+icon-default


.icon-spotify-light
	&:before
		content: $icon-spotify-light
		+icon-default


.icon-spray-filled
	&:before
		content: $icon-spray-filled
		+icon-default


.icon-spray-light
	&:before
		content: $icon-spray-light
		+icon-default


.icon-star-filled
	&:before
		content: $icon-star-filled
		+icon-default


.icon-star-light
	&:before
		content: $icon-star-light
		+icon-default


.icon-stop-filled
	&:before
		content: $icon-stop-filled
		+icon-default


.icon-stop-light
	&:before
		content: $icon-stop-light
		+icon-default


.icon-store-filled
	&:before
		content: $icon-store-filled
		+icon-default


.icon-store-light
	&:before
		content: $icon-store-light
		+icon-default


.icon-sun-filled
	&:before
		content: $icon-sun-filled
		+icon-default


.icon-sun-light
	&:before
		content: $icon-sun-light
		+icon-default


.icon-sync-filled
	&:before
		content: $icon-sync-filled
		+icon-default


.icon-sync-light
	&:before
		content: $icon-sync-light
		+icon-default


.icon-table-tennis-filled
	&:before
		content: $icon-table-tennis-filled
		+icon-default


.icon-table-tennis-light
	&:before
		content: $icon-table-tennis-light
		+icon-default


.icon-tablet-filled
	&:before
		content: $icon-tablet-filled
		+icon-default


.icon-tablet-light
	&:before
		content: $icon-tablet-light
		+icon-default


.icon-tag-filled
	&:before
		content: $icon-tag-filled
		+icon-default


.icon-tag-light
	&:before
		content: $icon-tag-light
		+icon-default


.icon-target-filled
	&:before
		content: $icon-target-filled
		+icon-default


.icon-target-light
	&:before
		content: $icon-target-light
		+icon-default


.icon-thermometer-filled
	&:before
		content: $icon-thermometer-filled
		+icon-default


.icon-thermometer-light
	&:before
		content: $icon-thermometer-light
		+icon-default


.icon-thumbs-down-filled
	&:before
		content: $icon-thumbs-down-filled
		+icon-default


.icon-thumbs-down-light
	&:before
		content: $icon-thumbs-down-light
		+icon-default


.icon-thumbs-up-filled
	&:before
		content: $icon-thumbs-up-filled
		+icon-default


.icon-thumbs-up-light
	&:before
		content: $icon-thumbs-up-light
		+icon-default


.icon-ticket-filled
	&:before
		content: $icon-ticket-filled
		+icon-default


.icon-ticket-light
	&:before
		content: $icon-ticket-light
		+icon-default


.icon-tile-view-filled
	&:before
		content: $icon-tile-view-filled
		+icon-default


.icon-tile-view-light
	&:before
		content: $icon-tile-view-light
		+icon-default


.icon-timer-filled
	&:before
		content: $icon-timer-filled
		+icon-default


.icon-timer-light
	&:before
		content: $icon-timer-light
		+icon-default


.icon-toggle-off-filled
	&:before
		content: $icon-toggle-off-filled
		+icon-default


.icon-toggle-off-light
	&:before
		content: $icon-toggle-off-light
		+icon-default


.icon-toggle-on-filled
	&:before
		content: $icon-toggle-on-filled
		+icon-default


.icon-toggle-on-light
	&:before
		content: $icon-toggle-on-light
		+icon-default


.icon-tool-filled
	&:before
		content: $icon-tool-filled
		+icon-default


.icon-tool-light
	&:before
		content: $icon-tool-light
		+icon-default


.icon-train-filled
	&:before
		content: $icon-train-filled
		+icon-default


.icon-train-light
	&:before
		content: $icon-train-light
		+icon-default


.icon-trash-filled
	&:before
		content: $icon-trash-filled
		+icon-default


.icon-trash-light
	&:before
		content: $icon-trash-light
		+icon-default


.icon-trending-down-filled
	&:before
		content: $icon-trending-down-filled
		+icon-default


.icon-trending-down-light
	&:before
		content: $icon-trending-down-light
		+icon-default


.icon-trending-up-filled
	&:before
		content: $icon-trending-up-filled
		+icon-default


.icon-trending-up-light
	&:before
		content: $icon-trending-up-light
		+icon-default


.icon-trophy-filled
	&:before
		content: $icon-trophy-filled
		+icon-default


.icon-trophy-light
	&:before
		content: $icon-trophy-light
		+icon-default


.icon-truck-filled
	&:before
		content: $icon-truck-filled
		+icon-default


.icon-truck-light
	&:before
		content: $icon-truck-light
		+icon-default


.icon-tune-filled
	&:before
		content: $icon-tune-filled
		+icon-default


.icon-tune-light
	&:before
		content: $icon-tune-light
		+icon-default


.icon-tv-filled
	&:before
		content: $icon-tv-filled
		+icon-default


.icon-tv-light
	&:before
		content: $icon-tv-light
		+icon-default


.icon-twitch-filled
	&:before
		content: $icon-twitch-filled
		+icon-default


.icon-twitch-light
	&:before
		content: $icon-twitch-light
		+icon-default


.icon-twitter-filled
	&:before
		content: $icon-twitter-filled
		+icon-default


.icon-twitter-light
	&:before
		content: $icon-twitter-light
		+icon-default


.icon-umbrella-filled
	&:before
		content: $icon-umbrella-filled
		+icon-default


.icon-umbrella-light
	&:before
		content: $icon-umbrella-light
		+icon-default


.icon-underline-filled
	&:before
		content: $icon-underline-filled
		+icon-default


.icon-underline-light
	&:before
		content: $icon-underline-light
		+icon-default


.icon-undo-filled
	&:before
		content: $icon-undo-filled
		+icon-default


.icon-undo-light
	&:before
		content: $icon-undo-light
		+icon-default


.icon-unlock-filled
	&:before
		content: $icon-unlock-filled
		+icon-default


.icon-unlock-light
	&:before
		content: $icon-unlock-light
		+icon-default


.icon-update-filled
	&:before
		content: $icon-update-filled
		+icon-default


.icon-update-light
	&:before
		content: $icon-update-light
		+icon-default


.icon-upload-filled
	&:before
		content: $icon-upload-filled
		+icon-default


.icon-upload-light
	&:before
		content: $icon-upload-light
		+icon-default


.icon-utensils-filled
	&:before
		content: $icon-utensils-filled
		+icon-default


.icon-utensils-light
	&:before
		content: $icon-utensils-light
		+icon-default


.icon-vector-filled
	&:before
		content: $icon-vector-filled
		+icon-default


.icon-vector-light
	&:before
		content: $icon-vector-light
		+icon-default


.icon-video-off-filled
	&:before
		content: $icon-video-off-filled
		+icon-default


.icon-video-off-light
	&:before
		content: $icon-video-off-light
		+icon-default


.icon-video-recorder-filled
	&:before
		content: $icon-video-recorder-filled
		+icon-default


.icon-video-recorder-light
	&:before
		content: $icon-video-recorder-light
		+icon-default


.icon-volume-filled
	&:before
		content: $icon-volume-filled
		+icon-default


.icon-volume-high-filled
	&:before
		content: $icon-volume-high-filled
		+icon-default


.icon-volume-high-light
	&:before
		content: $icon-volume-high-light
		+icon-default


.icon-volume-light
	&:before
		content: $icon-volume-light
		+icon-default


.icon-volume-off-filled
	&:before
		content: $icon-volume-off-filled
		+icon-default


.icon-volume-off-light
	&:before
		content: $icon-volume-off-light
		+icon-default


.icon-wallet-filled
	&:before
		content: $icon-wallet-filled
		+icon-default


.icon-wallet-light
	&:before
		content: $icon-wallet-light
		+icon-default


.icon-warning-filled
	&:before
		content: $icon-warning-filled
		+icon-default


.icon-warning-light
	&:before
		content: $icon-warning-light
		+icon-default


.icon-watch-filled
	&:before
		content: $icon-watch-filled
		+icon-default


.icon-watch-light
	&:before
		content: $icon-watch-light
		+icon-default


.icon-web-development-filled
	&:before
		content: $icon-web-development-filled
		+icon-default


.icon-web-development-light
	&:before
		content: $icon-web-development-light
		+icon-default


.icon-wifi-filled
	&:before
		content: $icon-wifi-filled
		+icon-default


.icon-wifi-light
	&:before
		content: $icon-wifi-light
		+icon-default


.icon-wifi-off-filled
	&:before
		content: $icon-wifi-off-filled
		+icon-default


.icon-wifi-off-light
	&:before
		content: $icon-wifi-off-light
		+icon-default


.icon-wind-filled
	&:before
		content: $icon-wind-filled
		+icon-default


.icon-wind-light
	&:before
		content: $icon-wind-light
		+icon-default


.icon-window-filled
	&:before
		content: $icon-window-filled
		+icon-default


.icon-window-light
	&:before
		content: $icon-window-light
		+icon-default


.icon-wine-filled
	&:before
		content: $icon-wine-filled
		+icon-default


.icon-wine-light
	&:before
		content: $icon-wine-light
		+icon-default


.icon-youtube-filled
	&:before
		content: $icon-youtube-filled
		+icon-default


.icon-youtube-light
	&:before
		content: $icon-youtube-light
		+icon-default


.icon-zap-filled
	&:before
		content: $icon-zap-filled
		+icon-default


.icon-zap-light
	&:before
		content: $icon-zap-light
		+icon-default


.icon-zoom-in-filled
	&:before
		content: $icon-zoom-in-filled
		+icon-default


.icon-zoom-in-light
	&:before
		content: $icon-zoom-in-light
		+icon-default


.icon-zoom-out-filled
	&:before
		content: $icon-zoom-out-filled
		+icon-default


.icon-zoom-out-light
	&:before
		content: $icon-zoom-out-light
		+icon-default


.icon-apple-logo
	&:before
		content: $icon-apple-logo


.icon-facebook-simple-logo
	&:before
		content: $icon-facebook-simple-logo
		+icon-default


.icon-google-logo
	&:before
		content: "\eb5f"
		color: rgb(66, 133, 244)
	&:after
		content: "\eb60"
		margin-left: -1em
		color: rgb(52, 168, 83)
	.path1
		&:before
			content: "\eb61"
			margin-left: -1em
			color: rgb(251, 188, 5)
	.path2
		&:before
			content: "\eb62"
			margin-left: -1em
			color: rgb(234, 67, 53)

.icon-instagram-logo
	&:before
		content: $icon-instagram-logo
		+icon-default


.icon-twitter-logo
	&:before
		content: $icon-twitter-logo
		+icon-default


.icon-down
	&:before
		content: $icon-down
		+icon-default


.icon-left
	&:before
		content: $icon-left
		+icon-default


.icon-right
	&:before
		content: $icon-right
		+icon-default


.icon-up
	&:before
		content: $icon-up
		+icon-default
