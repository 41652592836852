@import "_typography.module"
/* 
/* Typography
/* 
// Fonts
@import url('https://trend-production.s3.us-east-2.amazonaws.com/fonts/VG5000/font.css')
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap')

@font-face
	font-family: 'Lato'
	src: url(../fonts/LatoLatin-Regular.woff2) format('woff2')
	font-weight: 400
	font-display: swap

@font-face
	font-family: 'Lato'
	src: url(../fonts/LatoLatin-Semibold.woff2) format('woff2')
	font-weight: 600
	font-display: swap

@font-face
	font-family: 'Lato'
	src: url(..//fonts/LatoLatin-Heavy.woff2) format('woff2')
	font-weight: 800
	font-display: swap

@font-face
	font-family: 'Lato'
	src: url(..//fonts/LatoLatin-Black.woff2) format('woff2')
	font-weight: 900
	font-display: swap

// HTML Heading Elements
h1,h2,h3,h4,h5,h6
	+header

h1
	+h1

h2
	+h2

h3
	+h3

h4
	+h4

h5
	+h5

h6
	+h6

// Font Classes
.VG5000
	+VG5000

.DM-Sans
	+DM-Sans

// Heading Classes
.heading-xxlarge
	+heading-xxlarge

.heading-xlarge
	+heading-xlarge

.heading-large
	+heading-large

.heading-medium
	+heading-medium

.heading-small
	+heading-small

.heading-xsmall
	+heading-xsmall

// Other HTML Tags
p
	+p

a
	+a

ul
	+ul

ol
	+ol

li
	+li

blockquote
	+blockquote

// Text Sizes
.text-size-large
	+text-size-large

.text-size-medium
	+text-size-medium

.text-size-regular
	+text-size-regular

.text-size-small
	+text-size-small

.text-size-tiny
	+text-size-tiny

// Text Weights
.text-weight-xbold
	+text-weight-xbold

.text-weight-bold
	+text-weight-bold

.text-weight-semibold
	+text-weight-semibold

.text-weight-medium
	+text-weight-medium

.text-weight-normal
	+text-weight-normal

.text-weight-light
	+text-weight-light

// Text Letter Spacings
.text-letterspacing-xwide
	+text-letterspacing-xwide

.text-letterspacing-wide
	+text-letterspacing-wide

.text-letterspacing-normal
	+text-letterspacing-normal

.text-letterspacing-tight
	+text-letterspacing-tight

.text-letterspacing-xtight
	+text-letterspacing-xtight

// Text Line Heights
.text-lineheight-xtall
	+text-lineheight-xtall

.text-lineheight-tall
	+text-lineheight-tall

.text-lineheight-normal
	+text-lineheight-normal

.text-lineheight-short
	+text-lineheight-short

.text-lineheight-xshort
	+text-lineheight-xshort

.text-lineheight-none
	+text-lineheight-none

// Text Styles
.text-style-italic
	+text-style-italic

.text-style-strikethrough
	+text-style-strikethrough

.text-style-allcaps
	+text-style-allcaps

.text-style-nowrap
	+text-style-nowrap

.text-style-quote
	+text-style-quote

.text-style-link
	+text-style-link

.text-style-2lines
	+text-style-2lines

.text-style-3lines
	+text-style-3lines

.text-style-muted
	+text-style-muted

// Text Alignment
.text-align-left
	+text-align-left

.text-align-center
	+text-align-center

.text-align-right
	+text-align-right

// soona fonts
.badge-small
	+badge-small
