/**
/** Resets
/**
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5,
h6, p, blockquote, pre,a, abbr, acronym, address, big,
cite, code,del, dfn, em, img, ins, kbd, q, s, samp,small,
strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt,
dd, ol, ul, li,fieldset, form, label, legend,table, caption,
tbody, tfoot, thead, tr, th, td,article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary,time, mark, audio, video, input,option
	font-family: 'DM Sans', sans-serif
	margin: 0
	padding: 0
	border: 0
	vertical-align: baseline
html
	-ms-text-size-adjust: 100%
	-webkit-text-size-adjust: 100%
img,fieldset, a img
	border: none
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea
	-webkit-appearance: none

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected
	border: none !important
	background-color: inherit !important
	-webkit-text-fill-color: inherit !important
	-webkit-box-shadow: none !important

input[type="submit"],
button
	cursor: pointer
	&::-moz-focus-inner
		padding: 0
		border: 0
button
	background: none
textarea
	overflow: auto
input, button
	margin: 0
	padding: 0
	border: 0

div, input, textarea, select,button,
h1,h2,h3,h4,h5,h6,a,span,a:focus
	outline: none
ul,ol
	list-style-type: none
table
	border-spacing: 0
	border-collapse: collapse
	width: 100%
html
	box-sizing: border-box
*, *:before, *:after
	box-sizing: inherit

@media (prefers-reduced-motion: reduce)
	*
		transition: none !important

a
	text-decoration: none

svg,
img
	vertical-align: middle

select
	font-family: VG5000
	margin: 0
	appearance: none
	color: black
	background-color: white
	border-style: solid
	border-width: 1px
	border-color: black
	border-radius: 0.75rem
	padding: 0.5rem 1.5rem 0.5rem 1rem
	&::-ms-expand
		display: none
	background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%)
	background-position: center right 16px, center right 11px
	background-size: 5px 5px, 5px 5px
	background-repeat: no-repeat
	
	// &:focus
	// 	background-image: linear-gradient(45deg, black 50%, transparent 50%), linear-gradient(135deg, transparent 50%, black 50%)
	// 	background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em
	// 	background-size: 5px 5px, 5px 5px, 1px 1.5em